import React from 'react';
import { Button, Toast } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { faSearch, faPlus, faCircleNotch, faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ResourceForm from './resource-form';
import ResourceUpdate from './resource-update';
import HeaderBar from '../components/HeaderBar';
import Can from "../config/Can";
import { fetchResource, deleteResource, fetchClasses } from '../components/duck/operations';

import './style.scss';
import { withTranslation } from 'react-i18next';

class ResourceList extends React.Component {
    state = {
        check: false,
        toggleCreateModal: false,
        toggleUpdateModal: false,
        resources: [],
        isFetchingResource: false,
        messageBox: this.props.t('resourceComponent.static.selectFilter'),
        classID: null,
        classes: [],
        toast: {
            show: false,
            title: null,
            message: null
        },
        wantsToUpdateResource: null,
        localize: this.props.t
    }

    componentDidMount() {
        const role = localStorage.getItem('role');

        if(role !== 'student') {
            this.getClasses();
        }
        else{
            this.fetchResourceAPI();
        }
    }

    showCreateModal = () => { this.setState({ toggleCreateModal: !this.state.toggleCreateModal }); };
    showUpdateModal = (i) => {
        if (i >= 0) {
            const resource = { ...this.state.resources.resources[i] };

            this.setState({
                toggleUpdateModal: !this.state.toggleUpdateModal,
                wantsToUpdateResource: resource
            });
        }
    };

    closeUpdateModalAndFetch = () => {
        this.setState({ toggleUpdateModal: false, wantsToUpdateResource: null })
        this.fetchResourceAPI();
    }
    
    closeUpdateModal = () => {
        this.setState({ toggleUpdateModal: false, wantsToUpdateResource: null })
    }

    onSelectClass = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Class") {
                this.setState({ classID: e.target.value });
            }
        }
    }

    hideToast = () => {
        this.setState({
            toast: {
                show: false,
                message: null,
                title: null
            },
            isFetchingResource: false
        })
    }

    fetchResourceAPI = async () => {
        this.setState({ isFetchingResource: true })
        try {
            this.setState({ resources: "" });
            let classID;
            if (localStorage.getItem('role') === 'teacher') { classID = this.state.classID; }
            else if (localStorage.getItem('role') === 'student') { classID = localStorage.getItem('classID'); }

            if (!classID) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('shared.texts.selectOne', {type: this.state.localize('shared.static.class')}),
                        title: this.state.localize('shared.static.warning')
                    }
                });

                setTimeout(this.hideToast, 3000);

                return;
            }

            let studentResource = await this.props.actions.fetchResource(classID)
            if  (studentResource.message?.resources) {
                this.setState({ resources: studentResource.message }); 
            } else {
                this.setState({ messageBox: this.state.localize('resourceComponent.static.noResources') });
            }
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
        this.setState({ isFetchingResource: false })
    }

    handleDeleteResource = async (resource) => {
        this.setState({ isFetchingResource: true })
        try {
            let schoolID = this.state.resources.schoolID
            let classID = this.state.resources.classID
            let updatedResources = await this.props.actions.deleteResource(schoolID, resource._id, classID);
            if (updatedResources.code === 200) {
                this.showErrorInToast(this.state.localize('shared.texts.deleted', { module: this.state.localize('pages.resource', {action: ''}) }), this.state.localize('shared.static.success'))

                await this.fetchResourceAPI();
            }
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
        this.setState({ isFetchingResource: false })
    }

    getClasses = async () => {
        this.setState({ isFetchingResource: true });
        try {
            const response = await this.props.actions.fetchClasses();

            if (response) {
                this.setState({
                    classes: response
                });
            }
        } catch (error) {
            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message || this.state.localize('shared.static.serverError'),
                    title: this.state.localize('shared.static.warning')
                }
            });

            setTimeout(this.hideToast(), 3000);
        }
        this.setState({ isFetchingResource: false });
    }

    renderResourceList(resources) {
        if (resources.length < 1) {
            return <div className="list-container-message-box"> <h3> { this.state.messageBox } </h3> </div>
        }

        return <div className="resource-list__wrapper">
            <div className="resource-list__heading">
                <b>{this.state.localize('resourceComponent.tableHeadings.teacher')}</b>
                <b>{this.state.localize('resourceComponent.tableHeadings.text')}</b>
                <b>{this.state.localize('resourceComponent.tableHeadings.files')}</b>
                <b>{this.state.localize('resourceComponent.tableHeadings.pictures')}</b>
                <b>{this.state.localize('resourceComponent.tableHeadings.videos')}</b>
                <b className="text-right"></b>
            </div>

            {resources._id ?
                <div>
                    {resources && resources.resources.map((resourceList, i) => {
                        return (
                            <div className="resource-list__row" key={resourceList._id} id={resourceList._id}>
                                <span>{resourceList.heading}</span>
                                <span dangerouslySetInnerHTML={{ __html: resourceList.text }}></span>
                                <span>{resourceList.file ?
                                    <a href={resourceList.file} target="_blank" rel="noopener noreferrer"> {this.state.localize('resourceComponent.static.downloadFile')} </a> : this.state.localize('resourceComponent.text.file', { file: this.state.localize('shared.static.file')})}
                                </span>
                                <span>{resourceList.img ?
                                    <a href={resourceList.img} target="_blank" rel="noopener noreferrer"> {this.state.localize('resourceComponent.static.downloadImage')} </a> : this.state.localize('resourceComponent.text.file', { file: this.state.localize('shared.static.image')})}
                                </span>
                                <span>{resourceList.video ?
                                    <a href={resourceList.video} target="_blank" rel="noopener noreferrer"> {this.state.localize('resourceComponent.static.downloadVideo')} </a> : this.state.localize('resourceComponent.text.file', { file: this.state.localize('shared.static.video')})}
                                </span>
                                <span>{resourceList.meetingUrl ?
                                    <a href={resourceList.meetingUrl} target="_blank" rel="noopener noreferrer"> Meeting Link </a> :
                                    "No Meeting Link Available"}
                                </span>
                                <span className="text-right">
                                    <Can I='delete' a='Resource'>
                                        <Button variant="danger" style={{ margin: '0px 5px' }} id={resourceList._id} onClick={() => this.handleDeleteResource(resourceList)}> <FontAwesomeIcon icon={faTrashAlt} /> {this.state.localize('shared.buttons.delete')}</Button>
                                    </Can>
                                    <Can I="update" a='Resource'>
                                        <Button variant="info" style={{ margin: '0px 5px' }} id={resourceList._id} onClick={() => this.showUpdateModal(i)}> <FontAwesomeIcon icon={faEdit} /> {this.state.localize('shared.buttons.update')}</Button>
                                    </Can>
                                </span>
                            </div>
                        )
                    })}
                </div>
                : ""}
        </div>
    }

    showErrorInToast = (message, title) => {
        if(message === '') return;

        this.setState({
            toast: {
                show: !this.state.toast.show,
                message,
                title
            }
        });

        setTimeout(this.hideToast, 3000);
    }

    render() {
        const { resources, classes, isFetchingResource, toggleCreateModal, toggleUpdateModal, wantsToUpdateResource, classID } = this.state
        return (
            <div>
                <HeaderBar />
                <div
                    aria-live="polite"
                    aria-atomic="true">
                    <div
                        style={{
                            marginTop: '10px',
                            marginRight: '10px',
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            zIndex: '9999',
                            width: '280px'
                        }}
                    >
                        <Toast show={this.state.toast.show}>
                            <Toast.Header>
                                <strong className="mr-auto">{this.state.toast.title}</strong>
                            </Toast.Header>
                            <Toast.Body>{this.state.toast.message}</Toast.Body>
                        </Toast>
                    </div>
                </div>
                <div className="resource-list__container">
                    <div className="resource-list__header">
                        <h3>{this.state.localize('pages.resource', { action: '' })}</h3>
                        <div className="action-button__wrapper">
                            <Can I='read' a='Class'>
                                {/* <Class onSelect={this.onSelectClass}/> */}
                                <select className="form-control form-sm" style={{ width: '50%' }} disabled={isFetchingResource} onChange={this.onSelectClass}>
                                    <option>{ this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.class')}) }</option>
                                    {classes.map((c) => {
                                        return (
                                            <option value={c._id} key={c._id}>{c.name}</option>
                                        )
                                    })}
                                </select>
                            </Can>
                            <Button
                                className="search-button"
                                onClick={this.fetchResourceAPI}
                                disabled={isFetchingResource}>
                                {!isFetchingResource ? <FontAwesomeIcon icon={faSearch} /> : <FontAwesomeIcon icon={faCircleNotch} spin />}
                                {!isFetchingResource ? (' ' + this.state.localize('shared.buttons.search')) : (' ' + this.state.localize('shared.buttons.wait'))}
                            </Button>
                            {/*CAN USAGE */}
                            <Can I='create' a='Resource'> <Button className="search-button" onClick={() => { this.showCreateModal(); }}><FontAwesomeIcon icon={faPlus} /> {this.state.localize('pages.resource', { action: '' })}</Button> </Can>
                        </div>
                    </div>

                    <ResourceForm onClose={this.showCreateModal} show={toggleCreateModal} classes={classes} handleMessage={this.showErrorInToast}/>
                    <ResourceUpdate onClose={this.closeUpdateModal} onUpdate={this.closeUpdateModalAndFetch} show={toggleUpdateModal} classes={classes} handleMessage={this.showErrorInToast} resource={wantsToUpdateResource} classID={classID}/>

                    {
                        isFetchingResource ?
                            <div className="text-xl-center">
                                <FontAwesomeIcon style={{ width: '200px', height: '200px', color: '#5bc0de' }} icon={faCircleNotch} spin />
                            </div> :
                            this.renderResourceList(resources)
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        resourceList: state.componentReducer.resourceList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            fetchResource: fetchResource,
            deleteResource: deleteResource,
            fetchClasses: fetchClasses
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ResourceList));