import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import Date from '../components/date';
import { uploadImage, addAssignment, fetchSubject } from '../components/duck/operations';
import { withTranslation } from 'react-i18next';


class assignment extends React.Component {
    state = {
        show: false,
        title: null,
        img: [],
        isPostingAssignment: false,
        classID: '', subjectID: '',
        classes: [], subjects: [],
        localize: this.props.t
    }

    onClose = e => { this.props.onClose && this.props.onClose(e); };
    onSelectClass = (event) => { this.setState({ classID: event.target.value }, () => this.getSubjects()) }
    onSelectSubject = (event) => { this.setState({ subjectID: event.target.value }) }
    handleTitle = (event) => { this.setState({ title: event.target.value }) }
    handleImage = (event) => { this.setState({ img: event.target.files[0] }) }

    async getSubjects() {
        this.setState({
            isPostingHomework: true
        })

        try {
            let classID;

            if (localStorage.getItem('role') === 'teacher') { classID = this.state.classID; }
            else if (localStorage.getItem('role') === 'student') { classID = localStorage.getItem('classID'); }

            if (classID) {
                const subjects = await this.props.actions.fetchSubject(classID);
                if (subjects.length > 0) {
                    this.setState({ subjects });
                }
            }
        } catch (error) {
            this.props.handleMessage(error.message || this.state.localize('shared.static.serverError'), this.state.localize('shared.static.warning'))
        }

        this.setState({
            isPostingHomework: false
        })
    }

    //Conditions need to be updated here
    addAssignmentAPI = async () => {
        this.setState({ isPostingAssignment: true })
        try {
            if (this.validateInformationForCreating()) {
                let image = await this.props.actions.uploadImage(this.state.img);
                let studentAssignment = await this.props.actions.addAssignment(this.state.title, this.state.classID, this.state.subjectID, this.props.date, image.data);
                if (studentAssignment.success === true) {
                    this.setState({
                        img: [],
                        classID: '', subjectID: ''
                    })

                    this.onClose()

                    this.props.handleMessage('Assignment created succesfully', 'Success');
                }
            }

        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });

            this.props.handleMessage(error?.message || this.state.localize('shared.static.serverError'), "Warning");
        }
        this.setState({ isPostingAssignment: false })
    }

    validateInformationForCreating = () => {
        if (!this.props.date) {
            this.props.handleMessage(this.state.localize('assignmentComponent.validation.date'))

            return false;
        }

        if (!this.state.classID) {
            this.props.handleMessage(this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.class')}))

            return false;
        }


        if (!this.state.subjectID) {
            this.props.handleMessage(this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.subject')}))

            return false;
        }

        if (this.state.title.length === 0) {
            this.props.handleMessage(this.state.localize('assignmentComponent.validation.title'))

            return false;
        }

        if (this.state.img.length === 0) {
            this.props.handleMessage(this.state.localize('assignmentComponent.validation.file'))

            return false;
        }

        return true;
    }

    render() {
        const { isPostingAssignment } = this.state
        return (
            <div>
                <Modal size="lg" show={this.props.show} animation={false}>
                    <Modal.Header>
                        <Modal.Title>{this.state.localize('pages.assignment', { action: this.state.localize('shared.static.create')})}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <Container>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Modal.Body>
                                        <label forhtml="todaydate"> {this.state.localize('assignmentComponent.shared.deadline')} </label>
                                        <Date />
                                    </Modal.Body>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Modal.Body>
                                        <label>{ this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.class')}) } </label>
                                        {/* <Class onSelect={this.onSelectClass}/> */}
                                        <select className="form-control form-sm" onChange={this.onSelectClass} value={this.state.classID} disabled={this.state.isPostingHomework}>
                                            <option value="">{ this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.class')}) }</option>
                                            {
                                                this.props.classes.map((c, i) => <option value={c._id} key={i}>{c.name}</option>)
                                            }
                                        </select>
                                    </Modal.Body>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Modal.Body>
                                        <label>{ this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.subject')}) } </label>
                                        {/* <Subject default={this.state.class} onSelect={this.onSelectSubject}/> */}
                                        <select className="form-control form-sm" onChange={this.onSelectSubject} value={this.state.subjectID} disabled={this.state.isPostingHomework}>
                                            <option value="">{ this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.subject')}) }</option>
                                            {this.state.subjects.map((s) => <option value={s._id} key={s._id}>{s.title}</option>)}
                                        </select>
                                    </Modal.Body>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <Modal.Body>
                                        <label> {this.state.localize('assignmentComponent.shared.title')} </label>
                                        <textarea rows="1" onChange={this.handleTitle}></textarea>
                                    </Modal.Body>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <Modal.Body>
                                        <label> {this.state.localize('assignmentComponent.shared.selectFiles')} 
                                            <input type="file" onChange={this.handleImage} />
                                        </label>
                                    </Modal.Body>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.onClose}
                            disabled={isPostingAssignment}>
                            {this.state.localize('shared.buttons.close')}
                        </Button>
                        <Button
                            variant="success"
                            onClick={this.addAssignmentAPI}
                            disabled={isPostingAssignment}>
                            {!isPostingAssignment ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faCircleNotch} spin />}
                            {!isPostingAssignment ? (' ' + this.state.localize('shared.buttons.add')) : (' ' + this.state.localize('shared.buttons.wait'))}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        date: state.componentReducer.date,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            addAssignment: addAssignment,
            uploadImage: uploadImage,
            fetchSubject: fetchSubject
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(assignment));