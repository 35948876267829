import React from 'react';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getStudentProgressDetail, getRemarksOfStudentDetail } from '../../../components/duck/operations';
import { faCircleNotch, FontAwesomeIcon, Container, HeaderBar, faCheck } from '../../../quiz';
import { Toast, Row, Col, Button, Table } from 'react-bootstrap';
import * as moment from 'moment';
import { orderBy } from 'lodash';
import i18n from '../../../i18n';

import './style.scss';


class progressReportStudentDetails extends React.Component {
    state = {
        toast: {
            show: false,
            title: null,
            message: null
        },
        details: null,
        overall: {
            obtain_marks: 0,
            total_marks: 0,
            percentage: '',
            grade: ''
        },
        reports: [],
        remarks: null,
        localize: this.props.t
    }

    componentDidMount() {
        const progressJSON = JSON.parse(localStorage.getItem('student_progress_info'));

        if (!localStorage.getItem('student_progress_info') && !this.haveValidProperties(progressJSON)) {
            return this.props.history.replace('report-remarks-students/');
        }

        this.studentProgressDetails(progressJSON);
    }

    haveValidProperties = (progress) => progress?.class_id && progress?.template_id && progress?.student?.student_id;

    studentProgressDetails = async (progress) => {
        this.setState({ APIBeingCalled: true });

        try {
            let details = await this.props.actions.getStudentProgressDetail(progress.class_id, progress.template_id, progress.student.student_id);
            let remarks = await this.props.actions.getRemarksOfStudentDetail(progress.class_id, progress.template_id, progress.student.student_id);

            let subjects = details.data.subjects;
            let heads = [];

            let overall = {
                obtain_marks: 0,
                total_marks: 0,
                percentage: 0,
                grade: ''
            }

            subjects = subjects.map((subject, i) => {
                subject.total_marks = 0;
                subject.obtain_marks = 0;
                subject.heads = orderBy(subject.heads, ['_id'], ['asc']);

                subject.heads.forEach((head) => {
                    if (head.associate_with_grades) {
                        subject.total_marks += head.total_marks;
                        subject.obtain_marks += head.obtain_marks;
                    }
                    if (i === 0) {
                        heads.push({ title: head.title, total_marks: 0, obtain_marks: 0, associate_with_grades: head.associate_with_grades });
                    }
                })

                overall.obtain_marks += subject.obtain_marks;
                overall.total_marks += subject.total_marks;

                return subject;
            })


            if (overall.obtain_marks && overall.total_marks) {
                overall.percentage = ((overall.obtain_marks / overall.total_marks) * 100).toFixed(2);
            } else {
                overall.percentage = '-';
            }

            if (Object.keys(details.data.grade_scheme).includes('grades')) {
                overall.grade = details.data.grade_scheme.grades.filter((grade) => overall.percentage >= grade.from && overall.percentage <= grade.to).map((grade) => grade.grade);
            } else {
                overall.grade = '-';
            }

            heads = heads.map((head, i) => {
                details.data.subjects.forEach((item) => {
                    head.total_marks += item.heads[i].total_marks;
                    head.obtain_marks += item.heads[i].obtain_marks;
                });
                return head;
            })


            if (details.success) {
                this.setState({ 
                    ...this.state, 
                    details: Object.assign({}, {
                        student: details.data.student, 
                        schoolDetails: details.data.schoolDetails
                    }), 
                    subjects: subjects, heads: heads, overall: overall,
                    remarks: remarks.list.length > 0 ? remarks.list[0] : null
                });
            }
        } catch (error) {
            this.showToast(this.state.localize('shared.static.warning'), error?.response?.data?.message)
        }

        this.setState({ APIBeingCalled: false });
    }

    /* TOAST */

    showToast = (title, message) => {
        this.setState({
            toast: {
                show: true,
                title: title,
                message: message || this.state.localize('shared.static.serverError')
            }
        });

        setTimeout(this.hideToast, 3000);
    }

    hideToast = () => {
        this.setState({
            toast: {
                show: false,
                message: null,
                title: null
            },
            APIBeingCalled: false
        })
    }

    isDateValid = (...val) => !Number.isNaN(new Date(...val).valueOf());

    formatNumber = (number) => {
        return Number(number).toFixed(2);
    }

    /* RENDER */
    renderRemarksListing = () => {
        if (this.state.details) {
            return <>
                <Row>
                    <Col md={2} lg={2} xs={12} sm={12}>
                        <h5>{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.static.studentName') } </h5> <p>{this.state.details.student.firstName} {this.state.details.student.lastName}</p>
                    </Col>

                    <Col md={2} lg={2} xs={12} sm={12}>
                        <h5>{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.static.parentName') } </h5> <p>{this.state.details.student.fatherFirstName} {this.state.details.student.fatherLastName}</p>
                    </Col>

                    <Col md={2} lg={2} xs={12} sm={12}>
                        <h5>{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.static.rollNumber') } </h5> <p>{this.state.details.student.rollNumber}</p>
                    </Col>

                    <Col md={2} lg={2} xs={12} sm={12}>
                        <h5>{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.static.className') } </h5> <p>{this.state.details.student.className}</p>
                    </Col>

                    <Col md={2} lg={2} xs={12} sm={12}>
                        <h5>{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.static.branchName') } </h5> <p>{this.state.details.student.brachName}</p>
                    </Col>

                </Row>

                <Row>
                    <Col md={2} lg={2} xs={12} sm={12}>
                        <h5>{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.static.dateOfBirth') } </h5> <p>{!this.isDateValid(this.state.details.student.dateOfBirth) ? '-' : moment(this.state.details.student.dateOfBirth).locale(i18n.language).format('DD MMMM YYYY')}</p>
                    </Col>

                    <Col md={2} lg={2} xs={12} sm={12}>
                        <h5>{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.static.session') } </h5> <p>{this.state.details.schoolDetails.session}</p>
                    </Col>

                    <Col md={2} lg={2} xs={12} sm={12}>
                        <h5>{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.static.email') } </h5> <p>{this.state.details.student.emailAddress}</p>
                    </Col>

                    <Col md={2} lg={2} xs={12} sm={12}>
                        <h5>{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.static.admissionDate') } </h5> <p>{!this.isDateValid(this.state.details.student.admissionDate) ? '-' : moment(this.state.details.student.admissionDate).locale(i18n.language).format('DD MMMM YYYY')}</p>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} lg={12} xs={12} sm={12}>
                    <Table className="text-center">
                            <tbody>
                                <tr>
                                    <td rowSpan="2" className="table-border">{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.tableHeadings.subjects') }</td>
                                    <td colSpan="10" className="table-border">{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.tableHeadings.marks') }</td>
                                </tr>

                                <tr>
                                    {
                                        this.state.heads.map((head, i) => <td key={i} className="table-border">{head.title} { head.associate_with_grades && <FontAwesomeIcon icon={faCheck}/> }</td>)
                                    }
                                    <td className="table-border">{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.tableHeadings.total') }</td>
                                </tr>

                                {
                                    this.state.subjects.map((subject, i) => {
                                        return <tr key={i}>
                                            <td className="table-border">{subject.subject_name}</td>

                                            {
                                                subject.heads.map((head, i) => {
                                                    return <td key={i} className="table-border">
                                                        <div className="center-columns-table">
                                                            <span>{head.obtain_marks.toFixed(2)}</span>
                                                            <span>|</span>
                                                            <span>{head.total_marks}</span>
                                                        </div>
                                                    </td>
                                                })
                                            }

                                            <td className="table-border">
                                                <div className="center-columns-table">
                                                    <span>{subject.obtain_marks.toFixed(2)}</span>
                                                    <span>|</span>
                                                    <span>{subject.total_marks}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }

                                <tr>
                                    <th className="table-border">{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.static.totalMarks') }</th>

                                    {
                                        this.state.heads.map((head, i) => {
                                            return <td key={i} className="table-border">
                                                <div className="center-columns-table">
                                                    <span>{head.obtain_marks.toFixed(2)}</span>
                                                    <span>|</span>
                                                    <span>{head.total_marks}</span>
                                                </div>
                                            </td>
                                        })
                                    }

                                    <td className="table-border">
                                        <div className="center-columns-table">
                                            <span>{this.state.overall.obtain_marks.toFixed(2)}</span>
                                            <span>|</span>
                                            <span>{this.state.overall.total_marks}</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col md={12} lg={12} xs={12} sm={12}>
                        <Table className="table">
                            <thead>
                                <tr>
                                    <th>{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.tableHeadings.overallPercentage') }</th>

                                    <th>{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.tableHeadings.overallGrade') }</th>

                                    <th>{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.tableHeadings.classPosition') }</th>

                                    <th>{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.tableHeadings.classHigh') }</th>

                                    <th>{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.tableHeadings.classAverage') }</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>{this.state.overall.percentage}</td>
                                    <td>{this.state.overall.grade}</td>
                                    <td>{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.static.notAvailable') }</td>
                                    <td>{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.static.notAvailable') }</td>
                                    <td>{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.static.notAvailable') }</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                {
                    this.state.remarks &&
                    <Row className="mt-3">
                        <Col md={12} lg={12} xs={12} sm={12}>
                            <h4>{ this.state.localize('progressReportStudentComponent.progressReportStudentDetailComponent.static.remarks') } </h4>

                            <textarea disabled={true}>{ this.state.remarks.remarks || ''}</textarea>
                        </Col>
                    </Row>
                }
            </>
        }
    }

    /* BUTTONS */

    goBack = () => this.props.history.replace('/progress-report-students');

    render() {
        return <div>
            <HeaderBar />
            <div
                aria-live="polite"
                aria-atomic="true">
                <div
                    style={{
                        marginTop: '10px',
                        marginRight: '10px',
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        zIndex: '9999',
                        width: '280px'
                    }}
                >
                    <Toast show={this.state.toast.show}>
                        <Toast.Header>
                            <strong className="mr-auto">{this.state.toast.title}</strong>
                        </Toast.Header>
                        <Toast.Body>{this.state.toast.message}</Toast.Body>
                    </Toast>
                </div>
            </div>

            <Container fluid={true}>
                <Row>
                    <Col md={3} lg={3} xs={12} sm={12}>
                        <h4>
                            { this.state.localize('pages.student_progress_report_details') }
                        </h4>
                    </Col>

                    <Col md={9} lg={9} xs={12} sm={12} className="d-flex justify-content-end gap-5">
                        {/* <Button variant="primary" onClick={this.downloadReport} disabled={this.state.APIBeingCalled}> Download Report</Button> */}
                        <Button variant="danger" onClick={this.goBack} disabled={this.state.APIBeingCalled}> Close</Button>
                    </Col>
                </Row>

                <>
                    {
                        !this.state.APIBeingCalled ?
                            this.renderRemarksListing()
                            :
                            <div className="text-xl-center">
                                <FontAwesomeIcon style={{ width: '200px', height: '200px', color: '#5bc0de' }} icon={faCircleNotch} spin />
                            </div>
                    }
                </>
            </Container>
        </div>
    }
}

function mapStateToProps() {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getStudentProgressDetail: getStudentProgressDetail,
            getRemarksOfStudentDetail: getRemarksOfStudentDetail
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(progressReportStudentDetails));