//Image Fetch
export const FETCH_IMAGE_PENDING = 'FETCH_IMAGE_PENDING';
export const FETCH_IMAGE_SUCCESS = 'FETCH_IMAGE_SUCCESS';
export const FETCH_IMAGE_ERROR = 'FETCH_IMAGE_ERROR';
//export const FETCH_IMAGE = 'FETCH_IMAGE';

//PDF Fetch
export const FETCH_PDF_PENDING = 'FETCH_PDF_PENDING';
export const FETCH_PDF_SUCCESS = 'FETCH_PDF_SUCCESS';
export const FETCH_PDF_ERROR = 'FETCH_PDF_ERROR';
//export const FETCH_PDF = 'FETCH_PDF';

//Classes types
// export const FETCH_CLASS_ID = 'FETCH_CLASS_ID';
export const FETCH_CLASSES_PENDING = 'FETCH_CLASSES_PENDING';
export const FETCH_CLASSES_SUCCESS = 'FETCH_CLASSES_SUCCESS';
export const FETCH_CLASSES_ERROR = 'FETCH_CLASSES_ERROR';

//Subject types
// export const FETCH_SUBJECT_ID = 'FETCH_SUBJECT_ID';
export const FETCH_SUBJECT_PENDING = 'FETCH_SUBJECT_PENDING';
export const FETCH_SUBJECT_SUCCESS = 'FETCH_SUBJECT_SUCCESS';
export const FETCH_SUBJECT_ERROR = 'FETCH_SUBJECT_ERROR';

//Date Fetch
export const FETCH_DATE = 'FETCH_DATE';

//Attendance types
export const FETCH_ATTENDANCE_PENDING = 'FETCH_ATTENDANCE_PENDING';
export const FETCH_ATTENDANCE_SUCCESS = 'FETCH_ATTENDANCE_SUCCESS';
export const FETCH_ATTENDANCE_ERROR = 'FETCH_ATTENDANCE_ERROR';

//Homework Types
export const FETCH_HOMEWORK_PENDING = 'FETCH_HOMEWORK_PENDING';
export const FETCH_HOMEWORK_SUCCESS = 'FETCH_HOMEWORK_SUCCESS';
export const FETCH_HOMEWORK_ERROR = 'FETCH_HOMEWORK_ERROR';

//StudentID
export const FETCH_STUDENT = 'FETCH_STUDENT';

//Mark Attendance
export const MARK_ATTENDANCE_PENDING = 'MARK_ATTENDANCE_PENDING';
export const MARK_ATTENDANCE_SUCCESS = 'MARK_ATTENDANCE_SUCCESS';
export const MARK_ATTENDANCE_ERROR = 'MARK_ATTENDANCE_ERROR';

//Resource Types
export const FETCH_RESOURCE_PENDING = 'FETCH_RESOURCE_PENDING';
export const FETCH_RESOURCE_SUCCESS = 'FETCH_RESOURCE_SUCCESS';
export const FETCH_RESOURCE_ERROR = 'FETCH_RESOURCE_ERROR';

//Feedback Types
export const FETCH_FEEDBACK_PENDING = 'FETCH_FEEDBACK_PENDING';
export const FETCH_FEEDBACK_SUCCESS = 'FETCH_FEEDBACK_SUCCESS';
export const FETCH_FEEDBACK_ERROR = 'FETCH_FEEDBACK_ERROR';

//Assignment types
export const FETCH_ASSIGNMENT_ID = 'FETCH_ASSIGNMENT_ID';
export const FETCH_ASSIGNMENT_PENDING = 'FETCH_ASSIGNMENT_PENDING';
export const FETCH_ASSIGNMENT_SUCCESS = 'FETCH_ASSIGNMENT_SUCCESS';
export const FETCH_ASSIGNMENT_ERROR = 'FETCH_ASSIGNMENT_ERROR';

//Quiz types
export const FETCH_QUIZ_ID = 'FETCH_QUIZ_ID';
export const FETCH_QUIZ_PENDING = 'FETCH_QUIZ_PENDING';
export const FETCH_QUIZ_SUCCESS = 'FETCH_QUIZ_SUCCESS';
export const FETCH_QUIZ_ERROR = 'FETCH_QUIZ_ERROR';
export const DELETE_QUIZ_ERROR = 'DELETE_QUIZ_ERROR';

//Homework types
export const DELETE_HOMEWORK_ERROR = 'DELETE HOMEWORK_ERROR';

// ASSIGNMENT
export const DELETE_ASSIGNMENT_ERROR = 'DELETE_ASSIGNMENT_ERROR';
export const DELETE_ASSIGNMENT_COMMENT_ERROR = 'DELETE_ASSIGNMENT_COMMENT_ERROR';
export const ADD_ASSIGNMENT_COMMENT_ERROR = 'ADD_ASSIGNMENT_COMMENT_ERROR';
export const UPDATE_ASSIGNMENT_COMMENT = 'UPDATE_ASSIGNMENT_COMMENT';
export const DELETE_SUBMITTED_ASSIGNMENT_ERROR = 'DELETE_SUBMITTED_ASSIGNMENT_ERROR';

export const PROGRESS_HEADS_ERROR = 'PROGRESS_HEADS_ERROR';
export const PROGRESS_STUDENT_PROGRESS_ERROR = 'PROGRESS_STUDENT_PROGRESS_ERROR';
export const PROGRESS_STUDENT_UPDATE = 'PROGRESS_STUDENT_UPDATE';
export const PROGRESS_STUDENT_REMARKS = 'PROGRESS_STUDENT_REMARKS';
export const PROGRESS_STUDENT_REMARKS_UPDATE = 'PROGRESS_STUDENT_REMARKS_UPDATE';
export const PROGRESS_REMARK_STUDENT_ERROR = 'PROGRESS_REMARK_STUDENT_ERROR'
export const PROGRESS_REMARK_DELETE_ERROR = 'PROGRESS_REMARK_DELETE_ERROR'
export const PROGRESS_REPORT_STUDENT_REPORTS = 'PROGRESS_REPORT_STUDENT_REPORTS';