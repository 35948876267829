import {
    FETCH_CLASSES_PENDING, FETCH_CLASSES_SUCCESS, FETCH_CLASSES_ERROR, 
    FETCH_SUBJECT_PENDING, FETCH_SUBJECT_SUCCESS, FETCH_SUBJECT_ERROR, 
    // FETCH_CLASS_ID, FETCH_SUBJECT_ID, 
    FETCH_STUDENT, FETCH_DATE, 
    FETCH_IMAGE_PENDING, FETCH_IMAGE_SUCCESS, FETCH_IMAGE_ERROR,
    FETCH_PDF_PENDING, FETCH_PDF_SUCCESS, FETCH_PDF_ERROR,
    FETCH_ATTENDANCE_PENDING, FETCH_ATTENDANCE_SUCCESS, FETCH_ATTENDANCE_ERROR,
    FETCH_HOMEWORK_PENDING, FETCH_HOMEWORK_SUCCESS, FETCH_HOMEWORK_ERROR,
    MARK_ATTENDANCE_PENDING, MARK_ATTENDANCE_SUCCESS, MARK_ATTENDANCE_ERROR,
    FETCH_RESOURCE_PENDING, FETCH_RESOURCE_SUCCESS, FETCH_RESOURCE_ERROR,
    FETCH_FEEDBACK_PENDING, FETCH_FEEDBACK_SUCCESS, FETCH_FEEDBACK_ERROR,
    FETCH_ASSIGNMENT_PENDING, FETCH_ASSIGNMENT_SUCCESS, FETCH_ASSIGNMENT_ERROR,
    FETCH_ASSIGNMENT_ID, FETCH_QUIZ_ID,
    FETCH_QUIZ_PENDING, FETCH_QUIZ_SUCCESS, FETCH_QUIZ_ERROR,
    DELETE_HOMEWORK_ERROR,
    DELETE_ASSIGNMENT_ERROR, DELETE_ASSIGNMENT_COMMENT_ERROR, ADD_ASSIGNMENT_COMMENT_ERROR, UPDATE_ASSIGNMENT_COMMENT, DELETE_SUBMITTED_ASSIGNMENT_ERROR, DELETE_QUIZ_ERROR,
    PROGRESS_HEADS_ERROR, PROGRESS_STUDENT_PROGRESS_ERROR, PROGRESS_STUDENT_UPDATE, PROGRESS_STUDENT_REMARKS, PROGRESS_STUDENT_REMARKS_UPDATE, PROGRESS_REMARK_STUDENT_ERROR, PROGRESS_REMARK_DELETE_ERROR, PROGRESS_REPORT_STUDENT_REPORTS
} from './types';

export function fetchStudentDetails(student) {
    return { type: FETCH_STUDENT, payload: student, status: student.status, studentID: student.studentID }
}

export function fetchDate(date) {
        return { type: FETCH_DATE, payload: date }
}

export function fetchImagePending() {
    return { type: FETCH_IMAGE_PENDING }
}

export function fetchImageSuccess(image) {
    return { type: FETCH_IMAGE_SUCCESS, payload: image }
}

export function fetchImageError(error) {
    return { type: FETCH_IMAGE_ERROR, error: error }
}

export function fetchPdfPending() {
    return { type: FETCH_PDF_PENDING }
}

export function fetchPdfSuccess(pdf) {
    return { type: FETCH_PDF_SUCCESS, payload: pdf }
}

export function fetchPdfError(error) {
    return { type: FETCH_PDF_ERROR, error: error }
}

export function fetchClassesPending() {
    return { type: FETCH_CLASSES_PENDING }
}

export function fetchClassesSuccess(classList) {
    return { type: FETCH_CLASSES_SUCCESS, payload: classList }
}

export function fetchClassesError(error) {
    return { type: FETCH_CLASSES_ERROR, error: error }
}

export function fetchSubjectPending() {
    return { type: FETCH_SUBJECT_PENDING }
}

export function fetchSubjectSuccess(subjectList) {
    return { type: FETCH_SUBJECT_SUCCESS, payload: subjectList }
}

export function fetchSubjectError(error) {
    return { type: FETCH_SUBJECT_ERROR, error: error }
}

export function fetchAttendancePending() {
    return { type: FETCH_ATTENDANCE_PENDING }
}

export function fetchAttendanceSuccess(attendanceList) {
    return { type: FETCH_ATTENDANCE_SUCCESS, payload: attendanceList }
}

export function fetchAttendanceError(error) {
    return { type: FETCH_ATTENDANCE_ERROR, error: error }
}

export function fetchHomeworkPending() {
    return { type: FETCH_HOMEWORK_PENDING }
}

export function fetchHomeworkSuccess(homeworkList) {
//    console.log("Homework Fetch success: ", homeworkList)
    return { type: FETCH_HOMEWORK_SUCCESS, payload: homeworkList }
}

export function fetchHomeworkError(error) {
    return { type: FETCH_HOMEWORK_ERROR, error: error }
}

export function markAttendancePending() {
    return { type: MARK_ATTENDANCE_PENDING }
}

export function markAttendanceSuccess(data) {
    return { type: MARK_ATTENDANCE_SUCCESS, payload: data }
}

export function markAttendanceError(error) {
    return { type: MARK_ATTENDANCE_ERROR, error: error }
}

export function fetchResourcePending() {
    return { type: FETCH_RESOURCE_PENDING }
}

export function fetchResourceSuccess(resourceList) {
    return { type: FETCH_RESOURCE_SUCCESS, payload: resourceList }
}

export function fetchResourceError(error) {
    return { type: FETCH_RESOURCE_ERROR, error: error }
}

export function fetchFeedbackPending() {
    return { type: FETCH_FEEDBACK_PENDING }
}

export function fetchFeedbackSuccess(feedbackList) {
    return { type: FETCH_FEEDBACK_SUCCESS, payload: feedbackList }
}

export function fetchFeedbackError(error) {
    return { type: FETCH_FEEDBACK_ERROR, error: error }
}

export function fetchAssignmentID(assignmentID) {
    return { type: FETCH_ASSIGNMENT_ID, payload: assignmentID }
}

export function fetchAssignmentPending() {
    return { type: FETCH_ASSIGNMENT_PENDING }
}

export function fetchAssignmentSuccess(assginmentList) {
    return { type: FETCH_ASSIGNMENT_SUCCESS, payload: assginmentList }
}

export function fetchAssignmentError(error) {
    return { type: FETCH_ASSIGNMENT_ERROR, error: error }
}

export function fetchQuizID(quizID) {
    return { type: FETCH_QUIZ_ID, payload: quizID }
}

export function fetchQuizPending() {
    return { type: FETCH_QUIZ_PENDING }
}

export function fetchQuizSuccess(quizList) {
    return { type: FETCH_QUIZ_SUCCESS, payload: quizList }
}

export function fetchQuizError(error) {
    return { type: FETCH_QUIZ_ERROR, error: error }
}

export function deleteQuizError(error) {
    return { type: DELETE_QUIZ_ERROR, error: error }
}

// export function fetchClassID(classID) {
//     return { type: FETCH_CLASS_ID, payload: classID }
// }

// export function fetchSubjectID(subjectID) {
//     return { type: FETCH_SUBJECT_ID, payload: subjectID }
// }

export function deleteHomeworkError(error) {
    return { type: DELETE_HOMEWORK_ERROR, error: error }
}

export function deleteAssignmentError(error) {
    return { type: DELETE_ASSIGNMENT_ERROR, error: error }
}

export function deleteAssignmentComment(error) {
    return { type: DELETE_ASSIGNMENT_COMMENT_ERROR, error: error }
}

export function addAssignmentCommentError(error) {
    return { type: ADD_ASSIGNMENT_COMMENT_ERROR, error: error }
}

export function updateAssignmentError(error) {
    return { type: UPDATE_ASSIGNMENT_COMMENT, error: error }
}

export function deleteSubmittedAssignmentError(error) {
    return { type: DELETE_SUBMITTED_ASSIGNMENT_ERROR, error: error }
}

export function progressHeads(error) {
    return { type: PROGRESS_HEADS_ERROR, error: error }
}

export function progressStudent(error) {
    return { type: PROGRESS_STUDENT_PROGRESS_ERROR, error: error }
}

export function progressUpdateStudent(error) {
    return { type: PROGRESS_STUDENT_UPDATE, error: error }
}

export function getStudentRemarks(error) {
    return { type: PROGRESS_STUDENT_REMARKS, error: error }
}

export function updateStudentRemarks(error) {
    return { type: PROGRESS_STUDENT_REMARKS_UPDATE, error: error }
}

export function getTemplatesError(error) {
    return { type: PROGRESS_REMARK_STUDENT_ERROR, error: error }
}

export function deleteProgressRemark(error) {
    return { type: PROGRESS_REMARK_DELETE_ERROR, error: error }
}

export function getStudentReportError(error) {
    return { type: PROGRESS_REPORT_STUDENT_REPORTS, error: error }
}