import React from 'react';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';

class remarkAllModal extends React.Component {

    closeModal = e => { this.props.toggleModal && this.props.toggleModal(e); };
    updateRemark = event => { this.props.updateRemark && this.props.updateRemark(event.target.value); };
    remarkAll = () => { this.props.remark && this.props.remark(); };

    render() {
        return <Modal size="lg" show={this.props.show} animation={true} backdrop={true}>
            <Modal.Header>
                <Modal.Title>Remarks</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} lg={12} md={12}>
                            <textarea onChange={this.updateRemark}></textarea>
                        </Col>

                        <Col xs={12} sm={12} lg={12} md={12} className="d-flex justify-content-end gap-5 mt-2">
                            <Button variant="primary" onClick={this.closeModal}>Close</Button>
                            <Button variant="primary" onClick={this.remarkAll}>Save remarks</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    }
}

function mapStateToProps(state) {
    return {
        classID: state.componentReducer.classID,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({}, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(remarkAllModal));