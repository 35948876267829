import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

/* import Class from '../components/dropdowns/classList';
import Subject from '../components/dropdowns/subject'; */
import Date from '../components/date';

import { uploadImage, uploadPDF, addHomework, fetchSubject } from '../components/duck/operations';
import { withTranslation } from 'react-i18next';

class homework extends React.Component {
    state = {        
        show: false,
        isFileNotAttached: true,
        diaryContent: "", 
        img: [],
        isPostingHomework: false,
        classID: '', subjectID: '',
        files: [],
        subjects: [],
        localize: this.props.t
    }

    onClose = e => { this.props.onClose && this.props.onClose(e); };
    onSelectClass = (event) => { this.setState({ classID: event.target.value }, () => this.getSubjects()) }
    onSelectSubject = (event) => { this.setState({ subjectID: event.target.value }) }
    handleDescription = (event) => { this.setState({diaryContent: event.target.value}) }
    handleFiles = (event) => {
        this.setState({
            files : event.target.files, isFileNotAttached: false
        }) 
    }

    async getSubjects() {
        this.setState({
            isPostingHomework: true
        })

        try {
            let classID;

            if (localStorage.getItem('role') === 'teacher') { classID = this.state.classID; }
            else if (localStorage.getItem('role') === 'student') { classID = localStorage.getItem('classID'); }

            if (classID) {
                const subjects = await this.props.actions.fetchSubject(classID);
                if (subjects.length > 0) {
                    this.setState({ subjects });
                }
            }
        } catch (error) {
            this.props.handleMessage(error.message || this.state.localize('shared.static.serverError'))
        }

        this.setState({
            isPostingHomework: false
        })
    }

    addHomeworkAPI = async () => {
        this.setState({ isPostingHomework: true })
        try {
            if(this.validateInformationForCreating()) {
                let stateFiles = [...this.state.files];

                let filesURL = [];

                for(let i = 0; i < stateFiles.length; i++) {
                    if (typeof stateFiles[i] === "object") {
                        if(!stateFiles[i].type.startsWith("image")) {
                            let file = await this.props.actions.uploadPDF(stateFiles[i]);
    
                            filesURL.push(file.data)
                        } else {
                            let image = await this.props.actions.uploadImage(stateFiles[i]);
    
                            filesURL.push(image.data);
                        }
                    }
                }

                let studentHomework = await this.props.actions.addHomework(this.state.subjectID, this.state.classID, this.props.date, this.state.diaryContent, filesURL);

                if(studentHomework.success === true){
                    this.setState({
                        isFileNotAttached: true,
                        diaryContent: "",
                        isPostingHomework: false,
                        classID: null, subjectID: null,
                        files: []
                    })

                    this.props.handleMessage(this.state.localize('shared.texts.created', { module: this.state.localize('pages.homework', {action: ''}) }), this.state.localize('shared.static.success'))

                    this.onClose()
                } 

            }
        } catch (error) {
            this.props.handleMessage(error.message || this.state.localize('shared.static.server'))
        }
        this.setState({ isPostingHomework: false })
    }

    validateInformationForCreating = () => {
        if(!this.props.date) {
            this.props.handleMessage(this.state.localize('homeworkComponent.validation.assigned'), this.state.localize('shared.static.warning'))

            return false;
        }

        if(!this.state.classID) {
            this.props.handleMessage(this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.class')}), this.state.localize('shared.static.warning'))

            return false;
        }

        
        if(!this.state.subjectID) {
            this.props.handleMessage(this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.subject')}), this.state.localize('shared.static.warning'))

            return false;
        }

        /* if(this.state.files.length > 0) {
            this.props.handleMessage('Please select atleast one file.')
            
            return false;
        } */
        
        if(this.state.diaryContent.length === 0) {
            this.props.handleMessage(this.state.localize('homeworkComponent.validation.description'), this.state.localize('shared.static.warning'))

            return false;
        }

        return true;
    }

    render() {
        const { isPostingHomework } = this.state
        return (
            <div>
                <Modal size="lg" show={this.props.show} animation={false}>
                    <Modal.Header>
                        <Modal.Title>{this.state.localize('pages.homework', { action: this.state.localize('shared.static.create')})}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <Container>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Modal.Body className="form-group required">
                                        <label className="control-label" htmlFor="todaydate">{this.state.localize('homeworkComponent.shared.assignedDate')} </label>
                                        <Date/>
                                    </Modal.Body>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Modal.Body className="form-group required">
                                        <label className="control-label">{this.state.localize('shared.texts.class')}</label>
                                        {/* <Class default="" onSelect={this.onSelectClass}/> */}
                                        <select className="form-control form-sm" onChange={this.onSelectClass} value={this.state.classID} disabled={this.state.isPostingHomework}>
                                        <option value="">{this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.class')})}</option>
                                        {
                                            this.props.classes.map((c, i) => <option value={c._id} key={i}>{c.name}</option>)
                                        }
                                    </select>
                                    </Modal.Body>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Modal.Body className="form-group required">
                                        <label className="control-label">{this.state.localize('shared.texts.subject')}</label>
                                        {/* <Subject default={this.state.class} onSelect={this.onSelectSubject}/> */}
                                        <select className="form-control form-sm" onChange={this.onSelectSubject} value={this.state.subjectID} disabled={this.state.isPostingHomework}>
                                            <option value="">{this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.subject')})}</option>
                                            {this.state.subjects.map((s) => <option value={s._id} key={s._id}>{s.title}</option>)}
                                        </select>
                                    </Modal.Body>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <Modal.Body className="form-group">
                                        <label className="control-label" >{this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.file')})}
                                            <input type="file" onChange={this.handleFiles} multiple/>
                                        </label>
                                    </Modal.Body>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <Modal.Body className="form-group required">
                                        <label className="control-label"> {this.state.localize('homeworkComponent.shared.description')} </label>
                                        <textarea onChange={this.handleDescription}></textarea>
                                    </Modal.Body>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.onClose}>
                            {this.state.localize('shared.buttons.close')}
                        </Button>

                        <Button
                            variant="success"
                            onClick={this.addHomeworkAPI}
                            disabled={isPostingHomework}>
                            {!isPostingHomework ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faCircleNotch} spin />} 
                            {!isPostingHomework ? (' ' + this.state.localize('shared.buttons.add')) : (' ' + this.state.localize('shared.buttons.wait'))} 
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        // classID: state.componentReducer.classID,
        // subjectID: state.componentReducer.subjectID,
        date: state.componentReducer.date,
        image: state.componentReducer.image
    }
}

function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ 
          addHomework: addHomework, 
          uploadImage: uploadImage,
          uploadPDF: uploadPDF,
          fetchSubject: fetchSubject
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(homework));