import { AbilityBuilder } from '@casl/ability'
//import { ability } from './Can';

//Add Quiz Authentication here

export default function defineAbilitiesFor(role) {
  const { can, cannot, rules } = new AbilityBuilder();

  if (role === 'teacher'){
    can(['read', 'create', 'update',  'delete'], 'Homework');
    can(['read', 'update', 'create', 'delete'], 'Resource');
    can(['read', 'update', 'delete', 'create'], 'Feedback');
    can(['read', 'create', 'delete', 'update'], 'Attendance');
    can(['create', 'delete', 'update'], 'Quiz');
    can(['create', 'delete'], 'Assignment');
    can('read', 'Class');
    can('read', 'Submission');
    can('read', 'ProgressReport');
    can(['read', 'update'], 'StudentProgressRemarks')
  }
  else if(role === 'student'){
    //    can('read', 'all');
    can('read', ['Homework', 'Resource', 'Feedback', 'Assignment', 'Quiz', 'StudentProgress']);
    can('update', 'Assignment')
    cannot('read', 'Class');
  }
  //ability.update(rules);
  return rules;
}