import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import defineAbilitiesFor from '../../config/ability';
import { ability } from '../../config/Can';

import './style.scss';
import { authOperations } from "../duck";

import { withTranslation } from 'react-i18next';

class LoginContainer extends React.Component {
    state = {      
      code: '',
      isFetchLogin: false,
      error: null,
      redirectToReferrer: false,
      localize: this.props.t
    }

  handleChange = (event) => { this.setState({ code: event.target.value }); }

  login_role = async () => {
    try{
      this.setState({ isFetchLogin: true });
      let login = await this.props.actions.login({ login_code: this.state.code });
      if(login.user.role === 'student'){
        localStorage.setItem('classID', login.profile.classID)
      }
      this.setState({ redirectToReferrer: true});
      this.props.history.replace('/home')
      ability.update(defineAbilitiesFor(login.user.role))
    }catch(error){
      this.setState({ error: error.response.data.message || this.state.localize('shared.static.serverError') });
    }
    this.setState({ isFetchLogin: false })
  }

render() {
  const {redirectToReferrer} = this.state;
  if (redirectToReferrer || localStorage.getItem("role")) {
    return <Redirect to={"/home"} />;
  }

  const { t } = this.props;

  return (
      <div className="login__container">
        <div className="header">
          <img src="/imgs/queno-logo.png" alt="lOGO" width="10%" height="5%" />
          <h3>{t('queno')}</h3>
        </div>

        <div>
          <div>{t('loginComponent.texts.enterCode')} </div>
            <input type="text" onChange={this.handleChange} placeholder={t('loginComponent.placeholders.digits')} disabled={this.state.isFetchLogin}></input>
            <p className="message">{this.state.error}</p>

              <Button onClick={this.login_role} variant="primary" disabled={this.state.isFetchLogin}> 
                {this.state.isFetchLogin ? t('shared.buttons.process'): t('shared.buttons.submit')} 
                {this.state.isFetchLogin && <FontAwesomeIcon icon={faCircleNotch} spin/>}
              </Button>
          </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { auth: state.auth }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ 
      login: authOperations.login,
     }, dispatch)
  };
}

export default withTranslation()(connect(
  mapStateToProps, mapDispatchToProps
)(LoginContainer));