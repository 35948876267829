import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Toast } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { faArrowLeft, faCircleNotch, faFileUpload, faTrashAlt, faPenAlt, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment';
import './assignment-details.scss';
import {
    fetchAssignmentDetails,
    deleteAssignment,
    fetchAssignmentSubmitOrNot,
    deleteSubmittedAssignment,
} from '../components/duck/operations';
import Can from "../config/Can";
import AssignmentSubmissionForm from './assignmentSubmission-form';
import AssignmentSubmitList from './assignment-list';
import AssignmentUpdateForm from './assignment-update';
import Comment from './comment';
import HeaderBar from '../components/HeaderBar';
import './detail.scss';
import { withTranslation } from 'react-i18next';
import 'moment/locale/ar';
import i18n from '../i18n';

class AssignmentDetails extends React.Component {
    state = {
        submission: false,
        comment: false,
        listofSubmission: false,
        assignmentDetail: [],
        submittedAssignment: [],
        isFetchingAssignment: false,
        showUpdateModal: false,
        toast: {
            show: false,
            message: null,
            heading: null
        },
        localize: this.props.t
    }

    showToastWithError = (err, heading) => {
        this.setState({
            toast: {
                show: !this.state.toast.show,
                message: err,
                heading: heading
            }
        });

        setTimeout(this.hideToast, 3000);
    }

    hideToast = () => {
        this.setState({
            toast: {
                show: false,
                message: null,
                heading: null
            },
            isCreatingQuiz: false
        })
    }

    componentDidMount() { this.fetchAssignment(); }
    showSubmissionListModal = () => { this.setState({ listofSubmission: !this.state.listofSubmission }); }

    showSubmissionModal = () => {
        this.setState({ submission: !this.state.submission });
    };

    // Refresh data if date has been updated
    showUpdateAssignmentModal = () => { this.setState({ showUpdateModal: !this.state.showUpdateModal }); };

    fetchAssignment = async () => {
        this.setState({ isFetchingAssignment: true })
        try {
            this.setState({ assignmentDetail: [] })
            let studentAssignmentDetail = await this.props.actions.fetchAssignmentDetails(this.props.match.params.id);
            let assignmentCheck = await this.props.actions.fetchAssignmentSubmitOrNot(this.props.match.params.id);
            this.setState({ submittedAssignment: assignmentCheck.data });
            this.setState({ assignmentDetail: studentAssignmentDetail.data });
        } catch (error) {
            /* this.setState({ error: error.message || this.state.localize('shared.static.serverError') }); */

            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message || this.state.localize('shared.static.serverError')
                }
            });

            this.props.history.replace('/assignment');

            setTimeout(this.hideToast, 3000);
        }
        this.setState({ isFetchingAssignment: false })
    }

    // Handle the entire Assignment - DELETE
    handleDeleteAssignment = async (assignmentID) => {
        try {
            let isAssignmentDelete = await this.props.actions.deleteAssignment(this.props.match.params.id || assignmentID);
            if (isAssignmentDelete.success === true) { 
                this.showToastWithError('Successfully deleted assignment', 'Success')

                setTimeout(() => this.props.history.replace('/assignment'), 3000)
            }
        } catch (error) {
            /* this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message || this.state.localize('shared.static.serverError'),
                    heading: "Warning"
                }
            });

            setTimeout(this.hideToast, 3000); */
            this.showToastWithError(error?.response?.data?.message || "Server Error", 'Warning')
        }
    };

    // Student Deleting the submitted Assignment - DELETE
    deleteAssignment = async (submitted_assignmentID) => {
        this.setState({ isFetchingAssignment: true })
        try {
            await this.props.actions.deleteSubmittedAssignment(submitted_assignmentID);
            await this.fetchAssignment();

            this.showToastWithError('Successfully deleted assignment submission', 'Success')
        } catch (error) {
            console.log(error)
            /* this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message || "Server Error",
                    heading: "Warning"
                }
            }); */

            this.showToastWithError(error?.response?.data?.message || "Server Error", 'Warning')
        }
        this.setState({ isFetchingAssignment: false })
    }

    //Assignment details
    render_Assignment_Detail(assignment) {
        return <div className="assignment-detail-list__wrapper">
            {assignment._id && <div className="assignment-detail-action_buttons">
                {/* <span>{
                    assignment.attachments[0].url ? <a href={assignment.attachments[0].url} target="_blank" rel="noopener noreferrer"> Download your Assignment File </a> : "No File Available"}
                </span> */}
                <span>
                    <Can I='read' a='Submission'>
                        {
                            assignment.attachments[0].url &&
                            <a href={assignment.attachments[0].url} target="_blank" rel="noopener noreferrer">
                                <Button variant="success"> <FontAwesomeIcon icon={faDownload} /> {this.state.localize('assignmentComponent.shared.buttons.assignment', { action: this.state.localize('shared.buttons.download')})}</Button>
                            </a>
                        }
                        <Button variant="primary" id={assignment._id} onClick={() => this.showSubmissionListModal(assignment)}> {this.state.localize('assignmentComponent.shared.buttons.submission', { action: this.state.localize('shared.buttons.view') })}</Button>
                    </Can>
                    <Can I='read' a='Assignment'>
                        {this.state.submittedAssignment._id ?
                            <div>
                                {
                                    assignment.attachments[0].url &&
                                    <a href={assignment.attachments[0].url} target="_blank" rel="noopener noreferrer">
                                        <Button variant="success"> <FontAwesomeIcon icon={faDownload} />{this.state.localize('assignmentComponent.shared.buttons.assignment', { action: this.state.localize('shared.buttons.download') })}</Button>
                                    </a>
                                }
                                <a href={this.state.submittedAssignment.attachment.url} target="_blank" rel="noopener noreferrer">
                                    <Button variant="success"> <FontAwesomeIcon icon={faDownload} /> {this.state.localize('assignmentComponent.shared.buttons.submission', { action: this.state.localize('shared.buttons.download') })}</Button>
                                </a>
                                <Button variant="danger" onClick={() => this.deleteAssignment(this.state.submittedAssignment._id)}> <FontAwesomeIcon icon={faTrashAlt} /> {this.state.localize('assignmentComponent.shared.buttons.deleteFile')}</Button>
                            </div>
                            :
                            <div>
                                {
                                    assignment.attachments[0].url &&
                                    <a href={assignment.attachments[0].url} target="_blank" rel="noopener noreferrer">
                                        <Button variant="success"> <FontAwesomeIcon icon={faDownload} /> {this.state.localize('assignmentComponent.shared.buttons.assignment', { action: this.state.localize('shared.buttons.download') })}</Button>
                                    </a>
                                }
                                <Button className="inline-button-text" variant="primary" id={assignment._id} onClick={() => this.showSubmissionModal(assignment)}> <FontAwesomeIcon icon={faFileUpload} /> {this.state.localize('assignmentComponent.shared.buttons.submission', { action: this.state.localize('shared.buttons.submission') })}</Button>
                            </div>
                        }
                    </Can>
                </span>
            </div>}
        </div>
    }

    onUpdated = () => {
        this.fetchAssignment();
    }

    render() {
        const { isFetchingAssignment, assignmentDetail, submission, listofSubmission, showUpdateModal } = this.state
        return (
            <div>
                <HeaderBar />
                <div
                    aria-live="polite"
                    aria-atomic="true">
                    <div
                        style={{
                            marginTop: '10px',
                            marginRight: '10px',
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            zIndex: '9999',
                            width: '280px'
                        }}
                    >
                        <Toast show={this.state.toast.show}>
                            <Toast.Header>
                                <strong className="mr-auto">{ this.state.toast.heading }</strong>
                            </Toast.Header>
                            <Toast.Body>{this.state.toast.message}</Toast.Body>
                        </Toast>
                    </div>
                </div>
                <div className="assignment-detail-list__container">
                    <div className="assignment-detail-list__header">
                        {/* GO BACK */}
                        <div className="assignment-detail__heading">
                            <Link to={"/assignment"}> <FontAwesomeIcon size='2x' style={{ color: 'black', margin: '5px' }} icon={faArrowLeft} /> </Link>
                            {assignmentDetail._id && <h2>{assignmentDetail.title}</h2>}
                        </div>

                        {assignmentDetail._id ?
                            <div className="assignment-detail__wrapper">
                                <span><b>{this.state.localize('assignmentComponent.shared.created')}</b> {moment(assignmentDetail.created_at).locale(i18n.language).format('MM/DD/YYYY')}</span>
                                <span><b>{this.state.localize('assignmentComponent.shared.deadLine')}</b> {moment(assignmentDetail.dead_line).locale(i18n.language).format('MM/DD/YYYY')}</span>
                                <span>
                                    <Can I='read' a='Submission'>
                                        <Button variant="light" id={assignmentDetail._id} onClick={this.showUpdateAssignmentModal}> <FontAwesomeIcon icon={faPenAlt} /></Button>
                                        <Button variant="danger" id={assignmentDetail._id} onClick={() => this.handleDeleteAssignment(assignmentDetail._id)}> <FontAwesomeIcon icon={faTrashAlt} /> </Button>
                                    </Can>
                                </span>
                            </div>
                            : ""}
                    </div>

                    {
                        isFetchingAssignment ?
                            <div className="text-xl-center">
                                <FontAwesomeIcon style={{ width: '200px', height: '200px', color: '#5bc0de' }} icon={faCircleNotch} spin />
                            </div> :
                            this.render_Assignment_Detail(assignmentDetail)
                    }

                    <AssignmentSubmissionForm onUpdate={this.onUpdated} onClose={this.showSubmissionModal} show={submission} assignmentID={assignmentDetail._id} showToastError={this.showToastWithError}/>
                    {
                        localStorage.getItem('role') === 'teacher' && 
                        <AssignmentSubmitList onClose={this.showSubmissionListModal} show={listofSubmission} assignmentID={this.props.match.params.id} />
                    }
                    <AssignmentUpdateForm onUpdate={this.onUpdated} onClose={this.showUpdateAssignmentModal} assignmentID={assignmentDetail._id} date={moment(assignmentDetail.dead_line).locale(i18n.language).format('YYYY-MM-DD')} show={showUpdateModal} showToastError={this.showToastWithError}/>

                    {assignmentDetail._id && <h3>{this.state.localize('assignmentComponent.shared.comments')}</h3>}
                    {assignmentDetail._id && <Comment assignmentID={assignmentDetail._id} onError={this.showToastWithError} />}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        assignmentID: state.componentReducer.assignmentID
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            fetchAssignmentDetails: fetchAssignmentDetails,
            deleteAssignment: deleteAssignment,
            fetchAssignmentSubmitOrNot: fetchAssignmentSubmitOrNot,
            deleteSubmittedAssignment: deleteSubmittedAssignment
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AssignmentDetails));