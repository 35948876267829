import {
    fetchClassesPending, fetchClassesSuccess, fetchClassesError,
    fetchSubjectPending, fetchSubjectSuccess, fetchSubjectError,
    fetchAttendancePending, fetchAttendanceSuccess, fetchAttendanceError,
    fetchHomeworkPending, fetchHomeworkSuccess, fetchHomeworkError,
    markAttendancePending, markAttendanceSuccess, markAttendanceError,
    fetchResourcePending, fetchResourceSuccess, fetchResourceError,
    fetchFeedbackPending, fetchFeedbackSuccess, fetchFeedbackError,
    fetchImagePending, fetchImageSuccess, fetchImageError,
    fetchPdfSuccess,
    fetchAssignmentPending, fetchAssignmentSuccess, fetchAssignmentError,
    // fetchQuizPending, fetchQuizSuccess, 
    fetchQuizError,
    deleteAssignmentError,
    addAssignmentCommentError,
    updateAssignmentError,
    deleteSubmittedAssignmentError,
    deleteQuizError, progressHeads, progressStudent, progressUpdateStudent, getStudentRemarks, updateStudentRemarks,
    getTemplatesError, deleteProgressRemark, getStudentReportError
} from './actions';
import { apiService } from "./../../common/utils/api-service";

//GET APIs
const fetchClassesByAttendance = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(fetchClassesPending());
        apiService('get', 'class?page=1&limit=1000', null, null, null)
            .then((res) => {
                let responseData = res.data;
                dispatch(fetchClassesSuccess(responseData))
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchClassesError(errorResponse));
                reject(errorResponse);
            })
    })
};

const fetchClasses = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(fetchClassesPending());
        apiService('get', 'class?page=1&limit=1000', null, null, null)
            //        apiService('get', 'class', null, null, {"page":1, "limit":1000})
            .then((res) => {
                let responseData = res.data.list;
                dispatch(fetchClassesSuccess(responseData))
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchClassesError(errorResponse));
                reject(errorResponse);
            })
    })
};

const fetchSubject = (classID) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(fetchSubjectPending());
        apiService('get', 'class/subject/' + classID + '?page=1&limit=10', null, null, null)
            //        apiService('get','class/subject/' + classID, null, null, {"page":1, "limit":10})
            .then((res) => {
                let responseData = res.data.list;
                dispatch(fetchSubjectSuccess(responseData))
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchSubjectError(errorResponse));
                reject(errorResponse);
            })
    })
};

const uploadImage = (file) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(fetchImagePending())
        let formData = new FormData();
        formData.append('file', file);
        apiService('post', 'upload?category=images', formData, { 'Content-Type': 'multipart/form-data' }, null)
            .then((res) => {
                let responseData = res.data.data;
                dispatch(fetchImageSuccess(responseData))
                resolve(res.data)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchImageError(errorResponse))
                reject(errorResponse);
            })
    })
};

const uploadPDF = (file) => (dispatch) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('file', file);
        apiService('post', 'upload?category=pdf', formData, { 'Content-Type': 'multipart/form-data' }, null)
            .then((res) => {
                let responseData = res.data.data;
                dispatch(fetchPdfSuccess(responseData))
                resolve(res.data)
            }, (err) => {
                let errorResponse = err;
                reject(errorResponse);
            })
    })
};

const fetchAttendance = (query) => (dispatch) => {
    /* console.log({ classID, subjectID, date}) */
    return new Promise((resolve, reject) => {
        dispatch(fetchAttendancePending());
        apiService('get', '/attendance/student/?' + query, null, null, null)
            .then((res) => {
                let responseData = res.data.data;
                dispatch(fetchAttendanceSuccess(responseData))
                resolve(responseData)
            }, (err) => {
                let errorResponse = err.response.data;
                dispatch(fetchAttendanceError(errorResponse));
                reject(errorResponse);
            })
    })
};

//POST
const submitQuizAnswer = (query) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('post', 'student/quiz', query, null, null)
            .then((res) => {
                let responseData = res.data;
                dispatch(fetchAttendanceSuccess(responseData))
                resolve(responseData)
            }, (err) => {
                let errorResponse = err.response.data;
                dispatch(fetchAttendanceError(errorResponse));
                reject(errorResponse);
            })
    });
};

const markAttendance = (query) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(markAttendancePending());
        apiService('post', 'attendance/student/mark', query, null, null)
            .then((res) => {
                let responseData = res.data;
                dispatch(markAttendanceSuccess(responseData))
                resolve(responseData)
            }, (err) => {
                let errorResponse = err.response.data;
                dispatch(markAttendanceError(errorResponse))
                reject(errorResponse);
            })
    })
};

const fetchHomework = (classID, startDate, endDate, subjectID) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(fetchHomeworkPending());
        apiService('get', 'homework?classID=' + classID + '&dateFrom=' + startDate + '&dateTo=' + endDate + '&subjectID=' + subjectID, null, null, null)
            //        apiService('get', 'homework?classID='+ classID  + '&date=' + date, null, null, {"subject_id":  subjectID})
            .then((res) => {
                let responseData = res.data;
                dispatch(fetchHomeworkSuccess(responseData))
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchHomeworkError(errorResponse));
                reject(errorResponse);
            })
    })
};

const addHomework = (subjectID, classID, date, description, imageFiles) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('post', 'homework/create', {
            "subjectID": subjectID,
            "classID": classID,
            "dateOfDiary": date,
            "diaryContent": description,
            "diaryImage": imageFiles
        }, null, null)
            .then((res) => {
                //success, message, data
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchHomeworkError(errorResponse));
                reject(errorResponse);
            })
    })
};

const updateHomework = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(fetchHomeworkPending());
        apiService('put', 'homework/update', payload, null, null)
            .then((res) => {
                let responseData = res.data;
                dispatch(fetchHomeworkSuccess(responseData))
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchHomeworkError(errorResponse));
                reject(errorResponse);
            })
    })
};

const deleteHomework = (diaryID, schoolID, date, classID) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('post', 'delete/diaryToCalendar', {
            "diaryID": diaryID,
            "isDeleted": true,
            "schoolID": schoolID,
            "dateOfDiary": date,
            "classID": classID
        }, null, null)
            .then((res) => {
                let responseData = res.data;
                console.log(responseData)
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchFeedbackError(errorResponse));
                reject(errorResponse);
            })
    })
};

const fetchFeedback = (classID) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(fetchFeedbackPending());
        //        apiService('get', 'feeds/5e4911aa08c6730004a2b445', null, null, null)
        apiService('get', 'feeds/' + classID, null, null, null)
            .then((res) => {
                let responseData = res.data;
                dispatch(fetchFeedbackSuccess(responseData))
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchFeedbackError(errorResponse));
                reject(errorResponse);
            })
    })
};

// Class Feeds Check if they are saved as empty array or not
//http://34.216.243.16:8080/api/feeds/teacher/:schoolID
const addFeedback = (classID, feed) => (dispatch) => {
    let firstName = localStorage.getItem('firstName')
    let lastName = localStorage.getItem('lastName')
    let teacherID = localStorage.getItem('userID')
    // let teacherID = localStorage.getItem('teacherID')
    let schoolID = localStorage.getItem('schoolID')
    let authorType = localStorage.getItem('role')

    return new Promise((resolve, reject) => {
        apiService('post', 'feeds/teacher/' + schoolID, {
            "teacherName": firstName + ' ' + lastName,
            "teacherID": teacherID,
            "schoolID": schoolID,
            "classID": classID,
            "feed": feed,
            "authorType": authorType,
            "pushContent": parseHTMLToText(feed)
        }, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchFeedbackError(errorResponse));
                reject(errorResponse);
            })
    })
};

const updateFeed = (classID, feed, feedID) => (dispatch) => {
    let firstName = localStorage.getItem('firstName')
    let lastName = localStorage.getItem('lastName')
    let teacherID = localStorage.getItem('userID')
    // let teacherID = localStorage.getItem('teacherID')
    let schoolID = localStorage.getItem('schoolID')

    return new Promise((resolve, reject) => {
        apiService('post', 'feeds/update/teacher', {
            "teacherName": firstName + ' ' + lastName,
            "authorID": teacherID,
            "teacherID": teacherID,
            "schoolID": schoolID,
            "classID": classID,
            "feedID": feedID,
            "feed": feed,
            "authorType": "Teacher",
            "isDeleted": false,
            "isApproved": true,
            "pushContent": parseHTMLToText(feed),
        }, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchFeedbackError(errorResponse));
                reject(errorResponse);
            })
    })
};

const deleteFeed = (classID, feed, feedID) => (dispatch) => {
    let teacherID = localStorage.getItem('userID')
    // let teacherID = localStorage.getItem('teacherID')
    let schoolID = localStorage.getItem('schoolID')

    return new Promise((resolve, reject) => {
        apiService('post', 'feeds/update/teacher', {
            "authorID": teacherID,
            "schoolID": schoolID,
            "classID": classID,
            "feed": feed,
            "feedID": feedID,
            "authorType": "Teacher",
            "isApproved": true,
            "isDeleted": true,
            "pushContent": parseHTMLToText(feed),
        }, null, null)
        .then((res) => {
            let responseData = res.data;
            resolve(responseData)
        }, (err) => {
            let errorResponse = err;
            dispatch(fetchFeedbackError(errorResponse));
            reject(errorResponse);
        })
    })
};

const parseHTMLToText = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

const fetchResource = (classID) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(fetchResourcePending());
        apiService('post', 'getAllResourceByClass', {
            "classID": classID,
            "schoolID": localStorage.getItem('schoolID')
        }, null, null)
            .then((res) => {
                let responseData = res.data;
                dispatch(fetchResourceSuccess(responseData))
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchResourceError(errorResponse));
                reject(errorResponse);
            })
    })
};

const addResource = (classID, heading, content, image, pdf, video, meeting) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('post', 'createResource', {
            "classID": classID,
            "date": new Date().getTime(),
            "heading": heading,
            "content": content,
            "img": image,
            "file": pdf,
            "video": video,
            "meetingUrl": meeting
        }, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchResourceError(errorResponse));
                reject(errorResponse);
            })
    })
};

const updateResource = (resourcePayload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('post', 'updateResource', resourcePayload, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchResourceError(errorResponse));
                reject(errorResponse);
            })
    })
};

const deleteResource = (schoolID, resourceID, classID) => () => {
    return new Promise((resolve, reject) => {
        apiService('post', 'deleteResource', {
            "schoolID": schoolID,
            "resourceID": resourceID,
            "classID": classID
        }, null, null)
            .then((res) => {
                let responseData = res.data;
                console.log(responseData)
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                reject(errorResponse);
            })
    })
};

const fetchAssignment = (classID, subjectID) => (dispatch) => {
    // Teacher Class ID and SubjectID
    // 5edf99a3dfe1c91d043d64a3
    // 5e4864aae46b190004582a00

    // Student Class ID and SubjectID
    // 5e67644fe008c30004d3e6b2
    // 5e4864aae46b190004582a00

    return new Promise((resolve, reject) => {
        dispatch(fetchAssignmentPending());
        apiService('get', 'assignment', null, null, { "class_id": classID, "subject_id": subjectID })
            .then((res) => {
                let responseData = res.data;
                dispatch(fetchAssignmentSuccess(responseData))
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchAssignmentError(errorResponse));
                reject(errorResponse);
            })
    })
};

//Added by Teacher
const addAssignment = (title, classID, subjectID, date, image) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('post', 'assignment', {
            "title": title,
            "class_id": classID,
            "subject_id": subjectID,
            "attachments": [
                {
                    "title": title,
                    "url": image,
                    "description": "",
                    "type": "image"
                }
            ],
            "dead_line": date
        }, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchAssignmentError(errorResponse));
                reject(errorResponse);
            })
    })
};

const fetchAssignmentSubmittedList = (assignmentID) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(fetchAssignmentPending());
        apiService('get', 'assignment/submission/' + assignmentID, null, null, null)
            .then((res) => {
                let responseData = res.data;
                dispatch(fetchAssignmentSuccess(responseData))
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchAssignmentError(errorResponse));
                reject(errorResponse);
            })
    })
};

const fetchAssignmentDetails = (assignmentID) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('get', 'assignment-details/' + assignmentID, null, null, null)
            .then((res) => {
                let responseData = res.data;
                dispatch(fetchFeedbackSuccess(responseData))
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                reject(errorResponse);
            })
    })
};

// Teacher: Delete Assignment
// Api needs to be fixed
const deleteAssignment = (assignmentID) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('delete', 'assignment/' + assignmentID, null, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(deleteAssignmentError(errorResponse))
                reject(errorResponse);
            })
    })
};

const deleteAssignmentComment = (assignmentID) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('delete', 'assignment/comment/' + assignmentID, null, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(deleteAssignmentComment(errorResponse))
                reject(errorResponse);
            })
    })
};

// Teacher: Update Assignment
// Api needs to be fixed
// Selected Assignment ko update krna hai
const updateAssignment = (assignmentID, date) => () => {
    return new Promise((resolve, reject) => {
        apiService('put', 'assignment/' + assignmentID, { "dead_line": date }, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                reject(errorResponse);
            })
    })
};

//Submitted by Student
const assignmentSubmission = (assignmentID, title, description, image) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('post', 'assignment_submission', {
            "assignment_id": assignmentID,
            "attachment": {
                "title": title,
                "url": image,
                "description": description,
                "type": "doc"
            }
        }, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchAssignmentError(errorResponse));
                reject(errorResponse);
            })
    })
};

const fetchAssignmentComment = (assignmentID, limit = 10, page = 1) => (dispatch) => {
    return new Promise((resolve, reject) => {
        //comment?assignment_id=5f6b17b22366de06ea940958&limit=10
        apiService('get', 'assignment/comment', null, null, { "assignment_id": assignmentID, "limit": limit, "page": page })
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchAssignmentError(errorResponse));
                reject(errorResponse);
            })
    })
};

const addAssignmentComment = (referenceID, comment) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('post', 'assignment/comment', {
            "ref_id": referenceID,
            "comment": comment
        }, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(addAssignmentCommentError(errorResponse))
                reject(errorResponse);
            })
    })
};

const updateAssignmentComment = (commentID, referenceID, comment) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('put', 'assignment/comment/' + commentID, {
            "ref_id": referenceID,
            "comment": comment
        }, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(updateAssignmentError(errorResponse))
                reject(errorResponse);
            })
    })
}

const fetchAssignmentSubmitOrNot = (assignmentID) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('get', 'assignment_submission/' + assignmentID, null, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch()
                reject(errorResponse);
            })
    })
};

const deleteSubmittedAssignment = (assignmentID) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('delete', 'assignment_submission/' + assignmentID, null, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(deleteSubmittedAssignmentError(errorResponse))
                reject(errorResponse);
            })
    })
};

const fetchQuiz = (title, classID, subjectID, page = 1, limit = 10) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('get', 'quiz', null, null, { "title": title, "class_id": classID, "subject_id": subjectID, "page": page, "limit": limit })
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchQuizError(errorResponse));
                reject(errorResponse);
            })
    })
};

const addQuiz = (title, classID, subjectID, questions, startDate, endDate) => (dispatch) => {
    let teacherID = localStorage.getItem('userID');
    return new Promise((resolve, reject) => {
        apiService('post', 'quiz',
            {
                "title": title,
                "class_id": classID,
                "subject_id": subjectID,
                "teacher_id": teacherID,
                "questions": questions,
                "start_date": startDate,
                "end_date": endDate
            }, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchQuizError(errorResponse));
                reject(errorResponse);
            })
    })
};

const deleteQuiz = (quizID) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('delete', 'quiz/' + quizID, null, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(deleteQuizError(errorResponse))
                reject(errorResponse);
            })
    })
};

const updateQuiz = (quizID, title, questions, startDate, endDate) => () => {
    return new Promise((resolve, reject) => {
        apiService('put', 'quiz/' + quizID, {
            "title": title,
            "questions": questions,
            "start_date": startDate,
            "end_date": endDate
        }, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                console.log(err)
                let errorResponse = err;
                reject(errorResponse);
            })
    })
};

const fetchQuizResult = (quiz_id) => (dispatch) => {
    console.log("QUIZ ID in API: ", quiz_id);
    return new Promise((resolve, reject) => {
        apiService('get', 'quiz/student/' + quiz_id, null, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchQuizError(errorResponse));
                reject(errorResponse);
            })
    })
};

const fetchStudentResult = (subjectID = null, page = 1, limit = 10) => (dispatch) => {
    let student_id = localStorage.getItem('userID')
    return new Promise((resolve, reject) => {
        apiService('get', 'student/quiz/' + student_id, null, null, { "subject_id": subjectID, "page": page, "limit": limit })
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchQuizError(errorResponse));
                reject(errorResponse);
            })
    })
};

const fetchSelectedQuiz = (quiz_id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('get', 'quiz/' + quiz_id, null, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchQuizError(errorResponse));
                reject(errorResponse);
            })
    })
};

const updateSelectedQuiz = (quiz_id, payload) => () => {
    return new Promise((resolve, reject) => {
        apiService('put', 'quiz/' + quiz_id, payload, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                reject(errorResponse);
            })
    })
};

const uploadQuizImage = (file) => (dispatch) => {
    let formData = new FormData();
    formData.append('file', file);

    return new Promise((resolve, reject) => {
        apiService('post', 'upload', formData, { 'Content-Type': 'multipart/form-data' }, { "category": "images" })
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchQuizError(errorResponse));
                reject(errorResponse);
            })
    })
};

const fetchQuizById = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('get', 'quiz\\' + id, null, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(fetchQuizError(errorResponse));
                reject(errorResponse);
            })
    })
};

const getProgressHeads = (classID, session) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('get', 'progress-heads?class_id=' + classID + '&session=' + session, null, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(progressHeads(errorResponse))
                reject(errorResponse);
            })
    })
}

const getProgressMarking = (classID, subjectID, scoreID, session) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('get', 'student-progress?class_id=' + classID + '&subject_id=' + subjectID + '&head_id=' + scoreID + '&session=' + session, null, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(progressStudent(errorResponse))
                reject(errorResponse);
            })
    })
}

const saveProgressMarking = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('post', 'student-progress', payload, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(progressUpdateStudent(errorResponse));
                reject(errorResponse);
            })
    })
}

const getStudentProgressReport = (classID, session) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('get', 'student-progress/report?class_id=' + classID + '&session=' + session, null, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(progressStudent(errorResponse))
                reject(errorResponse);
            })
    })
}

const getStudentProgressDetail = (class_id, template_id, student_id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('get', 'student-progress/detail?class_id=' + class_id + '&template_id=' + template_id + '&student_id=' + student_id, null, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(progressStudent(errorResponse))
                reject(errorResponse);
            })
    })
}

const getRemarksOfStudents = (classID, templateID) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('get', 'progress-report/students/remark?class_id=' + classID + '&template_id=' + templateID, null, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(getStudentRemarks(errorResponse))
                reject(errorResponse);
            })
    })
}

const createRemark = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('post', 'progress-report/students/remark/', payload, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(updateStudentRemarks(errorResponse))
                reject(errorResponse);
            })
    })
}

const updateRemark = (remark_id, remarks) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('put', 'progress-report/students/remark/' + remark_id, { remarks }, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(updateStudentRemarks(errorResponse))
                reject(errorResponse);
            })
    })
}

const getTemplates = (classID, page = 0, limit = 10) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('get', 'progress-template?class_id=' + classID + '&page=' + page + '&limit=' + limit, null, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(getTemplatesError(errorResponse))
                reject(errorResponse);
            })
    })
}

const deleteProgressReportRemark = (remarkID) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('delete', 'progress-report/students/remark/' + remarkID, null, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(deleteProgressRemark(errorResponse))
                reject(errorResponse);
            })
    })
}

const getStudentReport = (classID, studentID) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('get', 'progress-report/student/report?class_id=' + classID + '&student_id=' + studentID, null, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(getStudentReportError(errorResponse))
                reject(errorResponse);
            })
    })
}

const getRemarksOfStudentDetail = (classID, templateID, studentID) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('get', 'progress-report/students/remark?class_id=' + classID + '&template_id=' + templateID + '&student_id=' + studentID, null, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(getStudentRemarks(errorResponse))
                reject(errorResponse);
            })
    })
}

const fetchProgressReportHeads = (class_id, session, subject_id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiService('get', 'progress-heads?class_id=' + class_id + '&session=' + session + '&subject_id=' + subject_id, null, null, null)
            .then((res) => {
                let responseData = res.data;
                resolve(responseData)
            }, (err) => {
                let errorResponse = err;
                dispatch(getStudentRemarks(errorResponse))
                reject(errorResponse);
            })
    })
}

export {
    fetchClasses, fetchSubject, uploadImage, uploadPDF,
    fetchAttendance, markAttendance,
    fetchHomework, addHomework, deleteHomework,
    fetchFeedback, addFeedback,
    fetchResource, addResource, deleteResource,
    fetchAssignment, addAssignment, deleteAssignment, updateAssignment,
    fetchAssignmentDetails, fetchAssignmentSubmittedList, assignmentSubmission,
    fetchAssignmentComment, addAssignmentComment, fetchAssignmentSubmitOrNot,
    deleteSubmittedAssignment,
    fetchQuiz, addQuiz, deleteQuiz, updateQuiz,
    fetchQuizResult, fetchStudentResult,
    fetchSelectedQuiz, updateSelectedQuiz, uploadQuizImage, fetchQuizById, submitQuizAnswer, deleteAssignmentComment, updateAssignmentComment, updateResource, updateFeed, deleteFeed, updateHomework,
    getProgressHeads, getProgressMarking, saveProgressMarking, getStudentProgressReport, getStudentProgressDetail, getRemarksOfStudents, updateRemark, getTemplates, createRemark, deleteProgressReportRemark,
    getStudentReport, getRemarksOfStudentDetail,
    fetchClassesByAttendance, fetchProgressReportHeads
}