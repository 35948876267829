import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from "react-router-dom";

import Can from "../config/Can";
import HeaderBar from '../components/HeaderBar';
import './style.scss';
import { withTranslation } from 'react-i18next';

class Homepage extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <div>
                <HeaderBar />
                {/* <div className="mainbox" >
                    <div className="box">
                        <Link to="/homework">
                            <Card>
                                <Card.Img variant="top" src="/imgs/icons/notebook1.png" />
                                <Card.Body>
                                    <Card.Title>Homework</Card.Title>
                                </Card.Body>
                            </Card>
                        </Link>

                        <Link to="/resource">
                            <Card>
                                <Card.Img variant="top" src="/imgs/icons/resourcesIcon.png" />
                                <Card.Body>
                                    <Card.Title>Resource</Card.Title>
                                </Card.Body>
                            </Card>
                        </Link>
                        <Link to='/assignment'>
                            <Card style={{ 'opacity': '100%' }}>
                                <Card.Img variant="top" src="/imgs/icons/result.png" />
                                <Card.Body>
                                    <Card.Title>Assignment</Card.Title>
                                </Card.Body>
                            </Card>
                        </Link>
                    </div>
                    <div className="box">
                        <Link to='/classfeed'>
                            <Card>
                                <Card.Img variant="top" src="/imgs/icons/envelope.png" />
                                <Card.Body>
                                    <Card.Title>Class Feed</Card.Title>
                                </Card.Body>
                            </Card>
                        </Link>
                        <Link to="/quiz">
                            <Card>
                                <Card.Img variant="top" src="/imgs/icons/notebook.png" />
                                <Card.Body>
                                    <Card.Title>Quiz</Card.Title>
                                </Card.Body>
                            </Card>
                        </Link>
                        <Can I='read' a='Attendance'>
                            <Link to="/attendance">
                                <Card>
                                    <Card.Img variant="top" src="/imgs/icons/register.png" />
                                    <Card.Body>
                                        <Card.Title>Attendance</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Can>
                        <Can not I='read' a='Attendance'>
                            <Card style={{ 'opacity': '0%'}}>
                                <Card.Img variant="top" src="/imgs/icons/result.png" />
                                <Card.Body>
                                    <Card.Title></Card.Title>
                                </Card.Body>
                            </Card>
                        </Can>
                    </div>
                </div> */}
                <Container fluid={true}>
                    <Row>
                        <Col lg={3} md={3} xs={12} sm={12}>
                            <Link to="/homework">
                                <Card>
                                    <Card.Img variant="top" src="/imgs/icons/notebook1.png" />
                                    <Card.Body className="text-center">
                                        <Card.Title>{t('pages.homework', { action: '' })}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>

                        <Col lg={3} md={3} xs={12} sm={12}>
                            <Link to="/resource">
                                <Card>
                                    <Card.Img variant="top" src="/imgs/icons/resourcesIcon.png" />
                                    <Card.Body className="text-center">
                                        <Card.Title>{t('pages.resource', { action: '' })}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>

                        <Col lg={3} md={3} xs={12} sm={12}>
                            <Link to='/assignment'>
                                <Card style={{ 'opacity': '100%' }}>
                                    <Card.Img variant="top" src="/imgs/icons/result.png" />
                                    <Card.Body className="text-center">
                                        <Card.Title>{t('pages.assignment', { action: '' })}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>

                        <Col lg={3} md={3} xs={12} sm={12}>
                            <Link to='/classfeed'>
                                <Card>
                                    <Card.Img variant="top" src="/imgs/icons/envelope.png" />
                                    <Card.Body className="text-center">
                                        <Card.Title>{t('pages.classFeed', { action: '' })}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col lg={3} md={3} xs={12} sm={12}>
                            <Link to="/quiz">
                                <Card>
                                    <Card.Img variant="top" src="/imgs/icons/about.png" />
                                    <Card.Body className="text-center">
                                        <Card.Title>{t('pages.quiz', { action: '' })}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>


                        <Can I='read' a='Attendance'>
                            <Col lg={3} md={3} xs={12} sm={12}>
                                <Link to="/attendance">
                                    <Card>
                                        <Card.Img variant="top" src="/imgs/icons/register.png" />
                                        <Card.Body className="text-center">
                                            <Card.Title>{t('pages.attendance', { action: '' })}</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        </Can>

                        <Can I='read' a='ProgressReport'>
                            <Col lg={3} md={3} xs={12} sm={12}>
                                <Link to="/progress-report-students">
                                    <Card>
                                        <Card.Img variant="top" src="/imgs/icons/progress-report.png" />
                                        <Card.Body className="text-center">
                                            <Card.Title>{t('pages.student_progress_report_details', { action: '' })}</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        </Can>

                        <Can I='read' a='StudentProgressRemarks'>
                            <Col lg={3} md={3} xs={12} sm={12}>
                                <Link to="/class-marking">
                                    <Card>
                                        <Card.Img variant="top" src="/imgs/icons/class_marking.png" />
                                        <Card.Body className="text-center">
                                            <Card.Title>{t('pages.update_class_report', { action: '' })}</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        </Can>

                        <Can I='update' a='StudentProgressRemarks'>
                            <Col lg={3} md={3} xs={12} sm={12} className="mt-3">
                                <Link to="/report-remarks">
                                    <Card>
                                        <Card.Img variant="top" src="/imgs/icons/remarks.png" />
                                        <Card.Body className="text-center">
                                            <Card.Title>{t('pages.remarks', { action: '' })}</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        </Can>

                        <Can I='read' a='StudentProgress'>
                            <Col lg={3} md={3} xs={12} sm={12}>
                                <Link to="/report-listing">
                                    <Card>
                                        <Card.Img variant="top" src="/imgs/icons/progress-report.png" />
                                        <Card.Body className="text-center">
                                            <Card.Title>{t('pages.progress_reports', { action: '' })}</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        </Can>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withTranslation()(Homepage);