import React from 'react';
import { Container, Row, Col, Button, Toast } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { faSearch, faPlus, faCircleNotch, faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import HeaderBar from '../components/HeaderBar';
import HomeworkForm from './homework-form';
import HomeworkUpdate from './homework-update';
import Can from "../config/Can";
import { fetchHomework, deleteHomework, fetchClasses, fetchSubject } from '../components/duck/operations';
import './style.scss';
import { withTranslation } from 'react-i18next';

class HomeworkList extends React.Component {
    state = {
        show: false,
        toggleUpdateModal: false,
        homeworks: [],
        isFetchingHomework: false,
        classes: [], subjects: [],
        classID: '', subjectID: '',
        startDate: null, endDate: null,
        toast: {
            show: false,
            message: null,
            title: null
        },
        homeworkTextLabel: this.props.t('homeworkComponent.static.selectFilter'),
        localize: this.props.t
    }

    closeUpdateModalAndFetch = () => {
        this.setState({ toggleUpdateModal: false, wantsToUpdateHomework: null })
        this.fetchHomework();
    }

    showToastWithError = (err, title) => {
        this.setState({
            toast: {
                show: !this.state.toast.show,
                message: err,
                title: title
            }
        });

        setTimeout(this.hideToast, 3000);
    }

    hideToast = () => {
        this.setState({
            toast: {
                show: false,
                message: null,
                title: null
            },
            isFetchingHomework: false
        })
    }

    closeUpdateModal = () => {
        this.setState({ toggleUpdateModal: false, wantsToUpdateHomework: null })
    }

    showUpdateModal = (i) => {
        if (i >= 0) {
            const homework = this.state.homeworks[i];

            this.setState({
                toggleUpdateModal: !this.state.toggleUpdateModal,
                wantsToUpdateHomework: { ...homework }
            });
        }
    };

    onSelectClass = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Class") {
                this.setState({ classID: e.target.value }, () => {
                    this.getSubjects()
                });
            }

        }
    }
    onSelectSubject = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Subject") {
                this.setState({ subjectID: e.target.value });
            }
        }
    }

    toggleCreateModal = () => { this.setState({ showCreateModal: !this.state.showCreateModal }); };

    componentDidMount() {
        if(localStorage.getItem('role') === 'teacher') {
            this.getClasses();
        } else {
            this.getSubjects();
        }
    }

    async getClasses() {
        this.setState({
            isFetchingHomework: true
        })

        try {
            const classes = await this.props.actions.fetchClasses();

            this.setState({
                classes
            })
        } catch (error) {
            /* this.setState({ error: error.message || this.state.localize('shared.static.serverError') }); */

            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message
                }
            });

            setTimeout(this.hideToast, 3000);
        }

        this.setState({
            isFetchingHomework: false
        })
    }

    async getSubjects() {
        this.setState({
            isFetchingHomework: true
        })

        try {
            let classID;

            if (localStorage.getItem('role') === 'teacher') { classID = this.state.classID; }
            else if (localStorage.getItem('role') === 'student') { classID = localStorage.getItem('classID'); }

            if (classID) {
                const subjects = await this.props.actions.fetchSubject(classID);
                if (subjects.length > 0) {
                    this.setState({ subjects });
                }
            }
        } catch (error) {
            /* this.setState({ error: error.message || this.state.localize('shared.static.serverError') }); */

            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message
                }
            });

            setTimeout(this.hideToast, 3000);
        }

        this.setState({
            isFetchingHomework: false
        })
    }

    handleStartDate = (date) => {
        this.setState({
            startDate: date
        })
    }

    handleEndDate = (date) => {
        this.setState({
            endDate: date
        })
    }

    fetchHomework = async () => {
        this.setState({ isFetchingHomework: true })
        try {
            if (!this.state.startDate) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('shared.texts.date', { type: this.state.localize('shared.static.start')}),
                        title: this.state.localize('shared.static.warning')
                    }
                });

                setTimeout(this.hideToast, 3000);

                return;
            }

            if (!this.state.endDate) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('shared.texts.date', { type: this.state.localize('shared.static.end')}),
                        title: this.state.localize('shared.static.warning')
                    }
                });

                setTimeout(this.hideToast, 3000);

                return;
            }

            this.setState({ homeworks: [] })
            let classID;
            if (localStorage.getItem('role') === 'teacher') { classID = this.state.classID; }
            else if (localStorage.getItem('role') === 'student') { classID = localStorage.getItem('classID'); }

            if (!classID) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('shared.texts.selectOne', { type: this.state.localize('shared.static.class')}),
                        title: this.state.localize('shared.static.warning')
                    }
                });

                setTimeout(this.hideToast, 3000);

                return;
            }

            if (!this.state.subjectID) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('shared.texts.selectOne', {type: this.state.localize('shared.static.subject')}),
                        title: this.state.localize('shared.static.warning')
                    }
                });

                setTimeout(this.hideToast, 3000);

                return;
            }

            let studentHomework = await this.props.actions.fetchHomework(
                classID,
                moment(this.state.startDate).locale('en').format('YYYY-MM-DD'),
                moment(this.state.endDate).locale('en').format('YYYY-MM-DD'),
                this.state.subjectID);

            this.setState({ 
                homeworks: studentHomework.list,
                homeworkTextLabel: studentHomework?.list?.length === 0 ? this.state.localize('shared.texts.notFound', { type: this.state.localize('pages.homework')}) : ''
            });
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
        this.setState({ isFetchingHomework: false })
    }

    handleDeleteHomework = async (diary_id, homework) => {
        this.setState({ isFetchingHomework: true })
        try {
            let updatedHomework = await this.props.actions.deleteHomework(diary_id, homework.schoolID, homework.day, homework.classID);
            if (updatedHomework.code === 200) { 
                this.showErrorInToast(this.state.localize('shared.texts.deleted', { module: this.state.localize('pages.homework', {action: ''}) }), this.state.localize('shared.static.success'))

                this.fetchHomework() 
            }
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
        this.setState({ isFetchingHomework: false })
    };

    showErrorInToast = (message, title) => {
        if(message === '') return;

        this.setState({
            toast: {
                show: !this.state.toast.show,
                message,
                title
            }
        });

        setTimeout(this.hideToast, 3000);
    }

    renderHomeworkList(homeworks) {
        if (homeworks.length < 1) {
            return <div className="list-container-message-box"> <h3> { this.state.homeworkTextLabel } </h3> </div>
        }

        return <div className="homework-list__wrapper">
            <div className="homework-list__heading">
                <b>{ this.state.localize('homeworkComponent.tableHeadings.teacher') }</b>
                <b>{ this.state.localize('homeworkComponent.tableHeadings.subject') }</b>
                <b>{ this.state.localize('homeworkComponent.tableHeadings.content') }</b>
                <b>{ this.state.localize('homeworkComponent.tableHeadings.file') }</b>
                <b className="text-right"></b>
            </div>

            <div id={homeworks._id}>
                {homeworks && homeworks.map((homework, i) => {
                    return <div key={i}>
                        {
                            homework.diary.map((diary, j) => {
                                return (
                                    <div key={j} id={diary._id} className="homework-list__row">
                                        <span>{diary.teacherName}</span>
                                        <span>{diary.subjectTitle}</span>
                                        <span>{diary.content}</span>
                                        <span>
                                            {/* {diary.diaryImage ?
                                                <a href={diary.diaryImage} target="_blank" rel="noopener noreferrer"> Download File </a> :
                                                "No Image Available"} */}
                                            {
                                                diary.diaryImage.length > 0 && Array.isArray(diary.diaryImage) ? diary.diaryImage.map((diary, i) => <a href={diary} key={i} target="_blank" rel="noopener noreferrer"> {i + 1} </a>) : this.state.localize('homeworkComponent.static.noAttachment')
                                            }
                                        </span>
                                        <span className="text-right">
                                            <Can I='delete' a='Homework'>
                                                <Button
                                                    variant="danger"
                                                    id={diary._id}
                                                    value="delete"
                                                    disabled={this.state.isFetchingHomework}
                                                    onClick={() => this.handleDeleteHomework(diary._id, homework)}>
                                                    <FontAwesomeIcon icon={faTrashAlt} /> { this.state.localize('shared.buttons.delete') }
                                            </Button>
                                            </Can>
                                            <Can I='update' a='Homework'>
                                                <Button variant="info" disabled={this.state.isFetchingHomework} style={{ margin: '0px 5px' }} id={diary._id} onClick={() => this.showUpdateModal(i)}> <FontAwesomeIcon icon={faEdit} /> { this.state.localize('shared.buttons.update') }</Button>
                                            </Can>
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </div>
                })}
            </div>
        </div>
    }

    render() {
        const { isFetchingHomework, homeworks, showCreateModal, startDate, endDate, wantsToUpdateHomework, classes, subjects } = this.state
        return (
            <div>
                <HeaderBar />
                <div
                    aria-live="polite"
                    aria-atomic="true">
                    <div
                        style={{
                            marginTop: '10px',
                            marginRight: '10px',
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            zIndex: '9999',
                            width: '280px'
                        }}
                    >
                        <Toast show={this.state.toast.show}>
                            <Toast.Header>
                                <strong className="mr-auto">{this.state.toast.title}</strong>
                            </Toast.Header>
                            <Toast.Body>{this.state.toast.message}</Toast.Body>
                        </Toast>
                    </div>
                </div>
                <Container fluid={true}>
                    <Row>
                        <Col xs={12} lg={1} md={12}>
                            <h3>{this.state.localize('pages.homework', { action: ''})}</h3>
                        </Col>

                        <Col xs={12} lg={11} md={12} className="d-flex justify-content-end" style={{ padding: '0px' }}>
                            <div style={{ marginRight: '5px', marginLeft: '8px' }} className="d-flex">
                                {/* <DatePicker
                                    className="date_picker"
                                    selected={startDate}
                                    onChange={date => this.onChange(date)}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    placeholderText={startDate ? startDate : "START DATE - END DATE"}
                                    value={endDate && moment(startDate).format('MM/DD/YYYY') + " - " + moment(endDate).format('MM/DD/YYYY')}
                                /> */}
                                <DatePicker
                                    className="date_picker"
                                    selected={startDate}
                                    onChange={date => this.handleStartDate(date)}
                                    timeInputLabel="Time:"
                                    placeholderText={startDate ? startDate : this.state.localize('homeworkComponent.static.startDate')}
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    showTimeInput
                                />

                                <DatePicker
                                    className="date_picker"
                                    selected={endDate}
                                    onChange={date => this.handleEndDate(date)}
                                    placeholderText={endDate ? endDate : this.state.localize('homeworkComponent.static.endDate')}
                                    timeInputLabel="Time:"
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    showTimeInput
                                />
                            </div>

                            <div style={{ marginRight: '5px', marginLeft: '8px' }}>
                                <Can I='read' a='Class'>
                                    {/* <Class default="" onSelect={this.onSelectClass} /> */}
                                    <select className="form-control form-sm" onChange={this.onSelectClass} disabled={isFetchingHomework} value={this.state.classID}>
                                        <option>{this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.class')})}</option>
                                        {classes.map((c) => {
                                            return (
                                                <option value={c._id} key={c._id}>{c.name}</option>
                                            )
                                        })}
                                    </select>
                                </Can>
                            </div>

                            <div style={{ marginRight: '5px', marginLeft: '8px' }}>
                                {/* <Subject default={this.state.class} onSelect={this.onSelectSubject} /> */}

                                <select className="form-control form-sm" onChange={this.onSelectSubject} disabled={isFetchingHomework} value={this.state.subjectID}>
                                    <option>{this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.subject')})}</option>
                                    {subjects.map((s) => {
                                        return (
                                            <option value={s._id} key={s._id}>{s.title}</option>
                                        )
                                    })}
                                </select>
                            </div>

                            <Button
                                style={{ marginRight: '5px', marginLeft: '8px' }}
                                className="search-button"
                                onClick={this.fetchHomework}
                                disabled={isFetchingHomework}>
                                {!isFetchingHomework ? <FontAwesomeIcon icon={faSearch} /> : <FontAwesomeIcon icon={faCircleNotch} spin />}
                                {!isFetchingHomework ? (' ' + this.state.localize('shared.buttons.search')) : (' ' + this.state.localize('shared.buttons.wait'))}
                            </Button>
                            <Can I='create' a='Homework'>
                                <Button
                                    className="search-button p-2"
                                    disabled={isFetchingHomework}
                                    onClick={() => { this.toggleCreateModal(); }}>
                                    <FontAwesomeIcon icon={faPlus} /> { this.state.localize('pages.homework', { action: ''})}
                                </Button>
                            </Can>
                        </Col>
                    </Row>
                </Container>
                <div className="homework-list__container">
                    {/* <div className="homework-list__header">
                        <h3>Homework</h3>
                        <div className="action-button__wrapper">
                            <DatePicker
                                className="date_picker"
                                selected={startDate}
                                onChange={date => this.onChange(date)}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                placeholderText={startDate ? startDate : "START DATE - END DATE"}
                                value={endDate && moment(startDate).format('MM/DD/YYYY') + " - " + moment(endDate).format('MM/DD/YYYY') }
                                // inline
                            />
                            <Can I='read' a='Class'> 
                                <Class default="" onSelect={this.onSelectClass}/>
                            </Can>
                            <Subject default={this.state.class} onSelect={this.onSelectSubject}/>
                            <Button
                                className="search-button"
                                onClick={this.fetchHomework}
                                disable={isFetchingHomework}>
                                {!isFetchingHomework ? <FontAwesomeIcon icon={faSearch} /> : <FontAwesomeIcon icon={faCircleNotch} spin />}
                                {!isFetchingHomework ? (' Search') : (' Wait')}
                            </Button>
                            <Can I='create' a='Homework'>
                                <Button 
                                    className="search-button p-2" 
                                    onClick={() => { this.showModal(); }}> 
                                    <FontAwesomeIcon icon={faPlus} /> Homework 
                                </Button>
                            </Can>
                        </div>
                    </div> */} {/* Homework Header closed */}

                    <HomeworkForm onClose={this.toggleCreateModal} show={showCreateModal} classes={classes} classID={this.state.classID} handleMessage={this.showErrorInToast}/>
                    <HomeworkUpdate onClose={this.closeUpdateModal} show={this.state.toggleUpdateModal} handleMessage={this.showErrorInToast} onUpdate={this.closeUpdateModalAndFetch} homework={wantsToUpdateHomework} classes={this.state.classes} subjects={this.state.subjects} classID={this.state.classID} subjectID={this.state.subjectID}  />
                    
                    {
                        isFetchingHomework ?
                            <div className="text-xl-center">
                                <FontAwesomeIcon style={{ width: '200px', height: '200px', color: '#5bc0de' }} icon={faCircleNotch} spin />
                            </div> :
                            this.renderHomeworkList(homeworks)
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        homeworkList: state.componentReducer.homeworkList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            fetchHomework: fetchHomework,
            deleteHomework: deleteHomework,
            fetchClasses: fetchClasses,
            fetchSubject: fetchSubject
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(HomeworkList));
